import axios from 'axios'

import config from '../conf'

const handleErrorResponse = (error) => {
  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx
  const {
    response: { data, status, statusText },
  } = error

  console.error('Failed:', { data, status, statusText })

  const er = new Error(data?.error_description || statusText)
  er.code = data?.error

  if (data) {
    Object.keys(data).forEach((key) => {
      er[key] = data[key]
    })
  }

  er.statusCode = status

  throw er
}

const request = async ({ url, method, data, headers }) => {
  // console.debug('request', method, url);

  try {
    const response = await axios({ url, method, data, headers })
    return response.data
  } catch (error) {
    if (!error.response) {
      console.error('Failed:', error)
      throw error
    }

    handleErrorResponse(error)
  }
}

export const login = async ({ username, password } = {}) => {
  const data = {
    username,
    password,
    client_id: config.NIKE_ELITE_CLIENT_ID,
    ux_id: config.NIKE_ELITE_UX_ID,
    grant_type: config.NIKE_ELITE_GRANT_TYPE,
  }

  return await request({
    method: 'POST',
    url: `${config.NIKE_ELITE_IDENTITY_SERVICE}/login`,
    data,
  })
}

export const refresh = async ({ refresh_token }) => {
  const data = {
    client_id: config.NIKE_ELITE_CLIENT_ID,
    refresh_token,
    grant_type: 'refresh_token',
  }

  return await request({
    method: 'POST',
    url: `${config.NIKE_ELITE_IDENTITY_SERVICE}/tokenRefresh`,
    data,
  })
}
