import { Cloudinary } from '@cloudinary/url-gen'

// aliased
import config from 'conf'

class CloudinaryHelper extends Cloudinary {
  constructor(opts = {}) {
    super({
      ...opts,
      cloud: {
        ...(opts.cloud || {}),
        cloudName: config.CLOUDINARY_CLOUD,
      },
    })
  }

  image(path) {
    return super.image(`${config.CLOUDINARY_DIRECTORY}/${path}`)
  }

  video(path) {
    return super.video(`${config.CLOUDINARY_DIRECTORY}/${path}`)
  }
}

const instance = new CloudinaryHelper()

export default instance
