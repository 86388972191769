import { memo } from 'react'
import { applyTo, pipe } from 'ramda'
import { defaultProps, propTypes } from 'lib/react'
import { useAuth } from 'components/AuthProvider'

export default applyTo(() => {
  const { logout } = useAuth()
  logout()

  return null
}, pipe(propTypes({}), defaultProps({}), memo))
