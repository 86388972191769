// modules
import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import _ from 'lodash'
import {
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Divider,
  Select,
  TextField,
  TextGroup,
  Modal,
} from '@nike/eds'
// lib
import Progress from 'components/Progress'
import { CREATE_BUYLIST, GET_USER_FILES } from 'lib/graphql/mutation'

// local
import styles from './AdminHome.module.scss'

import { useCurrentUser } from '../../../../components/UserProvider'
import Cron from 'react-js-cron'
import { CarouselStyled } from '@nike/nike-design-system-components'
import UploadCard from './UploadCard'
import { TranslatableString as TS } from '@nike/i18n-react'
import { isEmpty, map, path, pathOr } from 'ramda'
// import BirthdayCard from "../../../RepViews/views/AdminHome/BirthdayCard";
const R = require('ramda')
const XLSX = require('xlsx')

async function put(url, data) {
  try {
    const response = await axios.put(url, data)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default function AdminHome() {
  console.log('loading AdminHome')
  const [createBuyList, { loading }] = useMutation(CREATE_BUYLIST)
  const [modalVisible, setModalVisible] = useState(false)
  const [inputHasError, setInputHasError] = useState(false)
  const user = useCurrentUser()
  // console.log(user)
  // const userId = R.propOr('anonymous', 'name', user)
  const userEmail = R.propOr('anonymous', 'email', user)

  // const [getUserFiles, { loading }] = useMutation(GET_USER_FILES)
  const [userFiles, setUserFiles] = useState([])

  if (loading) return <Progress />

  // This needs to be refactored and split up..
  const EDS_FORM_NEW = () => {
    const [formData, setFormData] = useState({})
    const [cronValue, setCronValue] = useState('')
    const [buttonDisabled, setButtonDisabled] = useState(true)

    const sheetsList = formData.sheets?.map((sheet) => ({
      label: sheet,
      value: sheet,
    }))
    const checkSheetName = (objectsArray, sheetName) => {
      // console.log(`this is objectsArray: ${objectsArray}`)
      // console.log(`this is sheetName: ${sheetName}`)
      // console.log(objectsArray.includes(sheetName))
      return objectsArray.includes(sheetName)
    }

    // Code Split
    const handleFormSubmit = async (event) => {
      event.preventDefault()
      event.stopPropagation()
      // const userOkta = await oktaAuth.getUser();
      const nikeId = formData['contact-nike-id']
      const seasonYear = formData['season-year']
      const geo = formData['geo']
      const files = formData['files']
      const allProductsCheck = formData['allProductsCheck']
      const myDate = formData['myDate']
      const sheetSelected = formData['sheetSelected']
      const csvFiles = []
      let promises = []
      const title = `${seasonYear}_${geo}_${nikeId}_${allProductsCheck}_${cronValue}_${myDate}`
      const jsonObject = {
        title: title,
        contact: nikeId,
        cadence: cronValue,
        REGION_NAME_GEO: geo,
        SEASON_YEAR: seasonYear,
        end_date: myDate,
        all_products: allProductsCheck,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
      const jsonString = JSON.stringify(jsonObject)
      // If no files and all products checked, create fake data
      // else process input
      if (!files && allProductsCheck) {
        // Fake data for upload
        const data = [
          ['PRODUCT_CODE', 'SEASON_YEAR_DESCRIPTION', 'params'],
          ['NA', seasonYear, jsonString],
        ]
        const csvData = data.map((row) => row.join('|')).join('\n')

        csvFiles.push({
          type: 'CSV',
          fileId: `User/SPD/${title}`,
          fileBody: `PRODUCTLESS.csv`,
          data: csvData,
        })
        // might be unnecessary for this part since data is very small
        const csvFilesWithoutData = csvFiles.map((file) => _.omit(file, 'data'))
        // Get presigned URL from athleteService
        const res = await createBuyList({
          variables: {
            input: {
              files: csvFilesWithoutData,
            },
          },
        })
        const signed_url = res.data.createBuyList.files
        const file_URL = signed_url.buyListCreated_URL

        try {
          await put(file_URL, csvFiles[0].data)
          console.log(`S3 upload SUCCESS for ${title}`)
        } catch (e) {
          console.log(`S3 upload FAIL for ${title}`)
          console.log('Error', e)
        }
      } else {
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const reader = new FileReader()
          let promise = new Promise((resolve, reject) => {
            reader.onload = function (e) {
              const data = new Uint8Array(e.target.result)
              const workbook = XLSX.read(data, { type: 'array' })
              for (const sheetName of workbook.SheetNames) {
                console.log(`this is sheetName: ${sheetName}`)
                console.log(`this is sheetSelected: ${sheetSelected}`)
                if (checkSheetName(sheetSelected, sheetName)) {
                  // TODO: Make a function.. this is a mess
                  try {
                    const cleanFileName = file.name.replace('.xlsx', '')
                    const title = `${seasonYear}_${geo}_${nikeId}_${cleanFileName}_${sheetName}_${cronValue}_${myDate}`
                    const key = `buyList/${title}.csv`
                    const sheet = workbook.Sheets[sheetName]
                    const csv = XLSX.utils.sheet_to_csv(sheet, {
                      FS: '|',
                      RS: '\n',
                      blankrows: false,
                    })
                    let data = csv.split('\n').map((row) => row.split('|'))
                    let data_lc_header = data[0].map(function (item) {
                      return item.toLowerCase()
                    })
                    let productCodeColumnIndex =
                      data_lc_header.indexOf('prod cd')
                    if (productCodeColumnIndex === -1) {
                      productCodeColumnIndex =
                        data_lc_header.indexOf('product code')
                    }
                    if (productCodeColumnIndex === -1) {
                      productCodeColumnIndex =
                        data_lc_header.indexOf('material number')
                    }
                    if (productCodeColumnIndex === -1) {
                      productCodeColumnIndex =
                        data_lc_header.indexOf('style-clr')
                    }
                    if (productCodeColumnIndex !== -1) {
                      data[0][productCodeColumnIndex] = 'PRODUCT_CODE'
                    } else {
                      console.log('Product Code column not found')
                      setModalVisible(true)
                      // setInputHasError(true)
                      return
                    }
                    const paramsData = [
                      'params',
                      ...new Array(data.length - 1).fill(jsonString),
                    ]
                    // Add "SEASON_YEAR_DESCRIPTION" column
                    const seasonYearDescriptionData = [
                      'SEASON_YEAR_DESCRIPTION',
                      ...new Array(data.length - 1).fill(seasonYear),
                    ]
                    // Combine data
                    const combinedData = data.map((row, i) => [
                      ...row,
                      seasonYearDescriptionData[i],
                      paramsData[i],
                    ])
                    const csvData = combinedData
                      .map((row) => row.join('|'))
                      .join('\n')

                    csvFiles.push({
                      type: 'CSV',
                      fileId: `User/SPD/${title}`,
                      fileBody: `.csv`,
                      data: csvData,
                    })
                  } catch (error) {
                    console.log(`skipping sheet - ${sheetName}`)
                  }
                }
              }
              resolve()
            }
          })
          reader.readAsArrayBuffer(file)
          promises.push(promise)
        }
      }
      Promise.all(promises).then(async () => {
        const csvFilesWithoutData = csvFiles.map((file) => _.omit(file, 'data'))
        let responseList = []
        const chunkSize = 10 // Adjust this value based on what your server can handle
        for (let i = 0; i < csvFilesWithoutData.length; i += chunkSize) {
          const chunk = csvFilesWithoutData.slice(i, i + chunkSize)
          console.log('calling createBuyList')
          const res = await createBuyList({
            variables: {
              input: {
                files: chunk,
              },
            },
          })
          responseList.push(res)
        }
        const urlList = responseList.map(
          (json) => json.data.createBuyList.files,
        )
        // Athlete-service determines response -- files should return all signed urls
        const fileURLS = urlList.flat(Infinity)
        for (let i = 0; i < fileURLS.length; i++) {
          const file_key = fileURLS[i].buyListCreated_key
          const file_URL = fileURLS[i].buyListCreated_URL
          const file_name = csvFiles[i].fileId
          const file = csvFiles[i]['data']
          try {
            await put(file_URL, file)
            console.log(`S3 upload SUCCESS for ${file_name}`)
          } catch (e) {
            console.log(`S3 upload FAIL for ${file_name}`)
            console.log('Error', e)
          }
        }
      })
    }

    // TODO: Check if all required fields are filled
    // Supposedly there is a better alternative to useEffect here
    const isButtonDisabled = (formData, cronValue) => {
      const hasAllProductsCheck = formData['allProductsCheck']
      const hasFiles = formData['files'] && formData['files'].length > 0
      const hasSheetSelected =
        formData['sheetSelected'] && formData['sheetSelected'].length > 0
      const hasMandatoryFields =
        formData['contact-nike-id'] &&
        formData['season-year'] &&
        formData['geo'] &&
        formData['myDate']

      if (hasAllProductsCheck) {
        return !(
          hasMandatoryFields &&
          cronValue &&
          !hasFiles &&
          !hasSheetSelected
        )
      } else {
        return !(
          hasMandatoryFields &&
          hasFiles &&
          hasSheetSelected &&
          cronValue
        )
      }
    }

    useEffect(() => {
      setButtonDisabled(isButtonDisabled(formData, cronValue))
    }, [formData, cronValue])

    return (
      <>
        {/*<div>*/}
        {/*  <label>*/}
        {/*    <strong>Form Data: </strong>*/}
        {/*  </label>*/}
        {/*  <textarea*/}
        {/*    readOnly*/}
        {/*    style={{width: '100%', height: '200px', overflowY: 'scroll'}}*/}
        {/*    value={JSON.stringify(formData, null, 2)}*/}
        {/*  />*/}
        {/*</div>*/}

        <div className={styles.formStyle}>
          <form onSubmit={handleFormSubmit}>
            <TextGroup>
              <div>
                <TextField
                  onChange={(e) => {
                    setFormData({ ...formData, [e.target.id]: e.target.value })
                  }}
                  id="contact-nike-id"
                  hasErrors={false}
                  label="Nike ID"
                  subtitle="( e.g. ksmith )*"
                  placeholder="Enter Nike ID"
                />
                <TextField
                  onChange={(e) => {
                    setFormData({ ...formData, [e.target.id]: e.target.value })
                  }}
                  id="season-year"
                  hasErrors={false}
                  label="Season-Year"
                  subtitle="( e.g. SP25 or SZYY )*"
                  placeholder="Enter Season-Year"
                />
                <Select
                  id="geo"
                  options={[
                    { label: 'EUROPE', value: 'geo' },
                    {
                      label: 'GREATER CHINA',
                      value: 'geo',
                    },
                    { label: 'ASIA PACIFIC, LATIN AMERICA', value: 'geo' },
                    {
                      label: 'NORTH AMERICA',
                      value: 'geo',
                    },
                    { label: 'GLOBAL', value: 'geo' },
                  ]}
                  label="Geo"
                  subtitle="( eg. North America )*"
                  message="Check products' status for this geo."
                  onChange={(selected) => {
                    setFormData({
                      ...formData,
                      [selected.value]: selected.label,
                    })
                  }}
                />
                <Divider />
                <TextField
                  onChange={(e) => {
                    const filesArray = Array.from(e.target.files)
                    if (filesArray.length === 0) {
                      const {
                        [e.target.id]: _,
                        sheets: _test,
                        ...rest
                      } = formData
                      setFormData({ ...rest })
                    }
                    let allSheets = []
                    filesArray.forEach((file) => {
                      const reader = new FileReader()
                      reader.onload = (t) => {
                        const data = new Uint8Array(t.target.result)
                        const workbook = XLSX.read(data, { type: 'array' })
                        allSheets = [...allSheets, ...workbook.SheetNames]
                        setFormData({
                          ...formData,
                          sheets: allSheets,
                          [e.target.id]: filesArray,
                        })
                      }
                      reader.readAsArrayBuffer(file)
                    })
                  }}
                  id="files"
                  hasErrors={false}
                  label="BuyLists"
                  subtitle="Enter .xlsx*"
                  type="file"
                  accept=".xlsx"
                  multiple
                  dragAndDrop
                  onError={(e) => console.log(e)}
                  message={
                    <div style={{ maxWidth: '400px' }}>
                      <Select
                        id="sheetSelected"
                        label="Sheet Selection"
                        subtitle="Select sheets to process"
                        isMulti={true}
                        closeMenuOnSelect={false}
                        onChange={(selected) => {
                          if (selected) {
                            const selectedLabels = selected.map(
                              (option) => option.label,
                            )
                            setFormData({
                              ...formData,
                              sheetSelected: selectedLabels,
                            })
                          } else {
                            const { sheetSelected, ...rest } = formData
                            setFormData(rest)
                          }
                        }}
                        options={sheetsList}
                      />
                    </div>
                  }
                />
                <CheckboxGroup
                  id="checkBoxGroup"
                  label="Label"
                  hideLabel={true}
                  name="allProductsTestGroup"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.id]: e.target.checked,
                    })
                  }}
                >
                  <Checkbox
                    label="All Products"
                    value="false"
                    id="allProductsCheck"
                  />
                </CheckboxGroup>
              </div>
            </TextGroup>
            <br />
            <Divider />
            <label>
              <strong>Schedule my report for:</strong>
            </label>
            <Cron
              className={styles.myCron}
              id="cronField"
              value={cronValue}
              setValue={setCronValue}
              clockFormat="12-hour-clock"
              defaultPeriod="week"
              clearButton={false}
              allowedDropdowns={[
                'period',
                'months',
                'month-days',
                'week-days',
                'hours',
              ]}
              allowedPeriods={['week']}
            />
            <div>
              <TextField
                label="Report end date:"
                type="date"
                value={formData.myDate}
                id="myDate"
                hasErrors={false}
                errorMessage="Error"
                onChange={(e) => {
                  setFormData({ ...formData, [e.target.id]: e.target.value })
                }}
              />
            </div>
            <Divider />
            <ButtonGroup style={{ marginTop: '24px' }}>
              <Button type="submit" variant="primary" disabled={buttonDisabled}>
                Submit Form
              </Button>
            </ButtonGroup>
          </form>
        </div>
      </>
    )
  }

  const TEST = () => {
    return (
      <>
        <div>
          <CarouselStyled
            className={styles.carousel}
            slidesToShow={{ xs: 1, m: 3 }}
            columnGap={{ xs: '12px' }}
            title={
              <TS
                stringKey="admin-user-files"
                primaryValue="BuyLists"
                description="Title on the admin home user previous uploads"
              />
            }
            childContentFocusable
          >
            {isEmpty(userFiles)
              ? 'No files uploaded'
              : map(
                  ({ node }) => (
                    <UploadCard key={path('name', node)} {...node} />
                  ),
                  userFiles,
                )}
            {/*<UploadCard key="1" name="name" url="url" />*/}
          </CarouselStyled>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.adminWrapper}>
          {/*<TEST />*/}
          <EDS_FORM_NEW />
          <Modal
            isOpen={modalVisible}
            onDismiss={() => setModalVisible(false)}
            headerSlot={<p>File Upload Error</p>}
          >
            <p>Your BuyList did not have "Product Code", or "Prod Cd"</p>
          </Modal>
          {/*<button onClick={() => setModalVisible(true)}>Click Me!</button>*/}
        </div>
      </div>
    </>
  )
}
