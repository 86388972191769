import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { pick, propOr } from 'ramda'

import { useAuth } from '../../components/AuthProvider'
import { GET_CURRENT_USER_QUERY } from '../../lib/graphql/query'
import Progress from 'components/Progress'
import jwtDecode from 'jwt-decode'

const UserContext = React.createContext(null)

const UserProvider = React.memo(({ children }) => {
  console.log('Loadin user provider')
  const { isAuthenticated, isOktaUser, identity } = useAuth()

  // const { data, loading } = useQuery(GET_CURRENT_USER_QUERY, {
  //   skip: !isAuthenticated,
  //   errorPolicy: 'all',
  // })
  const loading = false
  let user = isAuthenticated
    ? {
        ...pick(['email', 'name', 'upmId'], identity || {}),
        // ...propOr({}, 'whoami', data),
      }
    : null

  console.log(isOktaUser, isAuthenticated, user)
  if (isOktaUser && isAuthenticated) {
    let oktaTokenStorage = localStorage.getItem('okta-token-storage')
    const oktaProfileToken = JSON.parse(oktaTokenStorage).idToken.idToken
    const decodedOktaProfileToken = jwtDecode(oktaProfileToken)
    user = { ...user }
    user.firstName = decodedOktaProfileToken.name.split(' ')[0]
    user.lastName = decodedOktaProfileToken.name.split(' ')[1]
    user.email = decodedOktaProfileToken.email
  }
  console.log(isOktaUser, isAuthenticated, user)

  return (
    <UserContext.Provider value={user}>
      {loading ? <Progress /> : children}
    </UserContext.Provider>
  )
})

export const useCurrentUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useCurrentUser must be used within a UserProvider')
  }
  return context
}

export default UserProvider
