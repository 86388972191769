import { useCallback, useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import Progress from 'components/Progress'
import { GET_POLLS_QUERY } from 'lib/graphql/query'
import { NikeI18nContext } from '@nike/i18n-react'
import { pathOr, pipe, prop, propOr } from 'ramda'
import { parse, serialize } from '@vl/js-lib/browser/url/querystring'

import styles from './ListPolls.module.scss'
import ResponsesCard from './ResponsesCard'
import Pagination from 'components/Pagination'

const PER_PAGE = 100
const parseQS = pipe(propOr('', 'search'), parse)
const calculateCursor = (page) =>
  (page === 1 ? null : page * PER_PAGE)?.toString()

export default function ListPolls() {
  const { i18nString } = useContext(NikeI18nContext)
  const location = useLocation()
  const history = useHistory()
  const { view = 'card', page: currentPage = 0 } = parseQS(location)
  const [isCardView, setIsCardView] = useState(view === 'card')

  const handleViewChange = useCallback(
    (view) => {
      history.push({
        pathname: location.pathname,
        search: serialize({
          ...parseQS(location),
          view,
        }),
      })
      setIsCardView(view === 'card')
    },
    [history, location],
  )

  const handlePageChange = useCallback(
    (page) => {
      history.push({
        pathname: location.pathname,
        search: serialize({
          ...parseQS(location),
          page,
        }),
      })
    },
    [history, location],
  )

  const { loading, data } = useQuery(GET_POLLS_QUERY, {
    errorPolicy: 'all',
    variables: {
      first: PER_PAGE,
      after: calculateCursor(currentPage),
    },
  })

  const pageInfo = pathOr({}, ['listSurveys', 'pageInfo'], data)
  const totalCount = pathOr(1, ['listSurveys', 'totalCount'], data)
  const hasPreviousPage = prop('hasPreviousPage', pageInfo)
  const hasNextPage = prop('hasNextPage', pageInfo)

  if (loading) return <Progress />

  const edges = pathOr([], ['listSurveys', 'edges'], data)

  // pull out all of the questions from the edges
  let questions = []

  edges.forEach(({ node }) => {
    node.categories.forEach((category) => {
      category.questions.forEach((question) => {
        questions.push({ ...question, handle: node.handle })
      })
    })
  })

  return (
    <div className={styles.ListPolls}>
      <div className={styles.header}>
        <h1>{'Polls'}</h1>
        <div
          className={styles.view}
          onClick={() => {
            handleViewChange(isCardView ? 'list' : 'card')
          }}
        >
          {isCardView
            ? i18nString({
                primaryValue: 'List View',
                stringKey: 'list-polls-view-change-label-list-view',
                description: 'Admin list polls view change label list view',
              })
            : i18nString({
                primaryValue: 'Card View',
                stringKey: 'list-polls-view-change-label-card-view',
                description: 'Admin list polls view change label card view',
              })}
        </div>
      </div>

      {isCardView && (
        <div className={styles.cards}>
          {questions.map((question) => (
            <ResponsesCard {...question} collapsed={false} />
          ))}
        </div>
      )}

      {!isCardView && (
        <div className={styles.list}>
          {questions.map((question) => (
            <ResponsesCard {...question} collapsed={true} />
          ))}
        </div>
      )}

      <Pagination
        currentPage={currentPage}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        loading={loading}
        onPageChange={handlePageChange}
        totalPages={Math.ceil(totalCount / PER_PAGE)}
      />
    </div>
  )
}
