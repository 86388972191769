// modules
import React from 'react'
import { DateTimeFormat } from '@nike/i18n-react'
import { prop } from 'ramda'
import { useQuery } from '@apollo/client'
import { Text } from '@nike/eds'

// local
import styles from './AthletePollResponse.module.scss'
import { ADMIN_GET_ATHLETE_BY_USER_ID } from 'lib/graphql/query'
import Progress from 'components/Progress'
import Error from 'components/Error'
import { Avatar } from '@nike/nike-design-system-components'

const AthletePollResponse = ({ choice, date, question, user }) => {
  const { data, loading } = useQuery(ADMIN_GET_ATHLETE_BY_USER_ID, {
    variables: {
      id: user.id,
    },
    errorPolicy: 'all',
  })

  //   console.log({ choice, date, question, user, data })

  if (loading) return <Progress />

  if (!data) return <Error />

  const athlete = data?.node?.athleteProfile
  return (
    <div className={styles.AthletePollResponse}>
      {/* {JSON.stringify({
                choice, date, question, user
            })} */}
      {/* <div>{JSON.stringify(data)}</div> */}

      <div className={styles.avatarContainer}>
        <Avatar
          image={{
            src: data?.node?.avatar?.url,
            alt: 'avatar',
          }}
          size="50px"
          classes={{
            Root: styles.avatar,
          }}
        />
      </div>

      <Text font="body-0.75" className={styles.name}>
        {prop('firstName', athlete)} {prop('middleName', athlete)}{' '}
        {prop('lastName', athlete)} {prop('nameSuffix', athlete)}
      </Text>

      <Text font="body-0.75" className={styles.name}>
        {choice.value}
      </Text>

      <div className={styles.dateContainer}>
        <div className={styles.time}>
          <DateTimeFormat
            date={new Date(date)}
            hour="numeric"
            minute="numeric"
            hour12={true}
          />
        </div>
        <div className={styles.date}>
          <DateTimeFormat
            date={new Date(date)}
            year="numeric"
            month="long"
            day="numeric"
          />
        </div>
      </div>
    </div>
  )
}

export default AthletePollResponse
