import { Config } from './definitions'

export const DAY_MS = 86400000
export const CP_CODE_KEY = 'CP_CODE_KEY'
export const CP_EXPIRE_KEY = 'CP_EXPIRE_KEY'

// permament in-memory vars
export let upmId = 'guest'
export let cpCode

/* Do not change any code in this file. It will be overwritten the next time code is generated.*/

/* This is the Minimmal Analytics Helper that formats events from the Registry */

/* 
    @param {pageEvent} pageEvent - the event definition
    @param {pageDetail} pageDetail - the content title or the containing card/componet. 
    @param {additionalProps} additionalProps - Do include properties, e.g. { properties: { view: { foo: 'bar' }}}
    */
export const formatPageEvent = (
  pageEvent,
  pageDetail,
  additionalProps = null,
) => {
  const pageType = pageEvent.properties.view.pageType
  const pageName =
    pageEvent.domain +
    '>' +
    pageEvent.properties.view.pageType +
    '>' +
    pageDetail
  const formattedProps = formatProps({
    event: pageEvent,
    pageName,
    pageType,
    pageDetail,
    additionalProps,
  })
  return { eventName: pageEvent.eventName, formattedProps }
}

/*
  @method fireTrackEvent
  @param {TrackEvent} trackEvent - The TrackEvent definition reference
  @param {PageDetail} pageDetail - the sub-page you're on. This property is 'typically' wildcarded, but the Detail enum is provided for developer conveinence.
  @param {pageType} pageType - Page the event is fired from. If not provided, it will try to use defaultRoute or derive it from the pageDetail
  @param {clickActivity} clickActivity - Click Activity (must include prefix if required by event). Example: "carousel-click-some carousel item"
  @param {additionalProps} additionalProps - any other properties. Must include top-level properties.xxx
*/
export const formatTrackEvent = (
  trackEvent,
  pageDetail,
  pageType = '',
  clickActivity = '',
  additionalProps = null,
) => {
  if (pageType === '') {
    pageType = console.warn('Missing pageType')
  }

  const pageName = trackEvent.domain + '>' + pageType + '>' + pageDetail
  const formattedProps = formatProps({
    event: trackEvent,
    pageName,
    pageType,
    pageDetail,
    clickActivity,
    additionalProps,
  })

  return { eventName: trackEvent.properties.eventName, formattedProps }
}

const formatProps = ({
  event,
  pageName,
  pageType,
  clickActivity = null,
  pageDetail,
  additionalProps,
}) => {
  if (!additionalProps) {
    additionalProps = {}
  }

  parseCpCode()
  if (cpCode) {
    additionalProps.cpCode = cpCode
  }

  const additionalViewProps = {
    view: {
      pageName,
      pageType,
      pageDetail,
      experienceType: event.experienceType,
    },
  }

  if (clickActivity) {
    additionalViewProps['clickActivity'] = clickActivity
  }

  return {
    ...additionalProps,
    ...additionalViewProps,
    ...Config.appConstants,
  }
}

/* Campaign Code Helpers */

/**
 * Helper function to parse the code from url if not already loaded.
 * @param cp <string>
 * @returns
 */
export const parseCpCode = () => {
  if (!cpCode) {
    cpCode = getCpCode()
    if (!cpCode) {
      setCpCodeFromUrl()
    }
  }
}

export const setCpCodeFromUrl = () => {
  if (typeof window === 'undefined') {
    return
  }
  const urlParams = new URLSearchParams(window.location.search)
  const code = urlParams.get('cpCode')
  const cp = urlParams.get('cp')
  cpCode = code ? code : cp
  if (cpCode) {
    storeCpCode(cpCode)
  }
}

/**
 * Helper function to set cpCode in local storage.
 * Sets a hidden flag for the code to expire in one week.
 *
 * @param cp <string>
 * @returns
 */
export const storeCpCode = (cp, daysToExpire = 7) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(CP_CODE_KEY, cp)
    window.localStorage.setItem(
      CP_EXPIRE_KEY,
      `${+new Date() + daysToExpire * DAY_MS}`,
    )
  }
}
/**
 * Checks the browsers local storage for the cpCode and
 * returns it if it is present and not expired.
 *
 * @returns cpCode <string | null>
 */
export const getCpCode = () => {
  if (typeof window !== 'undefined') {
    const expire = localStorage.getItem(CP_EXPIRE_KEY)

    if (expire && +expire - +new Date() > 0) {
      return localStorage.getItem(CP_CODE_KEY)
    } else {
      clearCpCode()
    }
  }
}

/**
 * Checks the browsers local storage for the cpCode and
 * returns it if it is present and not expired.
 *
 * @returns cpCode <string | null>
 */
export const clearCpCode = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(CP_EXPIRE_KEY)
    localStorage.removeItem(CP_CODE_KEY)
  }
}

/**
 * When ios posts any message, this will post the cpCode to the
 * WKWebview Defined Handler with the cpCode as the messge content.
 */
if (typeof window !== 'undefined') {
  window.addEventListener(
    'message',
    () => {
      // parse ios messge, respond accordingly....
      parseCpCode()

      // @ts-expect-error: may not be defined
      if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.cpHandler
      ) {
        // @ts-expect-error: may not be defined
        window.webkit.messageHandlers.cpHandler.postMessage({
          message: cpCode,
        })
      }
    },
    false,
  )
}
