import {
  __,
  always,
  concat,
  identity,
  ifElse,
  includes,
  pathOr,
  pipe,
  startsWith,
} from 'ramda'
import packageJson from '../package.json'

const AKAMAI_WEB_URL = process.env.REACT_APP_WEB_URL
const DOMAIN_NAME = process.env.REACT_APP_DOMAIN_NAME
const ROOT_URL = pipe(
  pathOr('', ['location', 'host']),
  ifElse(
    includes(__, [AKAMAI_WEB_URL, DOMAIN_NAME]),
    identity,
    always(AKAMAI_WEB_URL),
  ),
  ifElse(startsWith('localhost'), concat('http://'), concat('https://')),
)(window || {})

const Config = {
  VERSION: packageJson.version,
  NODE_ENV: process.env.REACT_APP_INFRA_NODE_ENV,
  STAGE: process.env.REACT_APP_STAGE,
  ROOT_URL,
  API_HOST: process.env.REACT_APP_API_URL,
  OKTA_URL: process.env.REACT_APP_OKTA_URL,
  OKTA_CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID,
  OKTA_REDIRECT_URI: `${ROOT_URL}/admin/callback`,
  CLOUDINARY_CLOUD: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  CLOUDINARY_DIRECTORY: process.env.REACT_APP_CLOUDINARY_DIRECTORY,
  AIRTABLE_BLOG_TOKEN: process.env.REACT_APP_AIRTABLE_BLOG_TOKEN,
  AIRTABLE_BLOG_BASE_ID: process.env.REACT_APP_AIRTABLE_BLOG_BASE_ID,
  AIRTABLE_BLOG_TABLE_ID: process.env.REACT_APP_AIRTABLE_BLOG_TABLE_ID,
  AIRTABLE_FORYOUCOPY_TABLE_ID:
    process.env.REACT_APP_AIRTABLE_FORYOUCOPY_TABLE_ID,
  AIRTABLE_BLOG_URL: process.env.REACT_APP_AIRTABLE_URL,
  // TODO Add these to the infra repo as SSM params and update Jenkinsfile to load them
  // NEWRELIC_APPID: process.env.NEWRELIC_APPID,
  // NEWRELIC_LICENSE: process.env.NEWRELIC_LICENSE,
  // ANALYTICS_SEGMENTKEY: process.env.ANALYTICS_SEGMENTKEY,
  // ANALYTICS_SEGMENTKEY_VL: process.env.ANALYTICS_SEGMENTKEY_VL,

  NIKE_ELITE_IDENTITY_SERVICE:
    process.env.REACT_APP_NIKE_ELITE_IDENTITY_SERVICE,
  NIKE_ELITE_CLIENT_ID: process.env.REACT_APP_NIKE_ELITE_CLIENT_ID,
  NIKE_ELITE_UX_ID: process.env.REACT_APP_NIKE_ELITE_UX_ID,
  NIKE_ELITE_GRANT_TYPE: process.env.REACT_APP_NIKE_ELITE_GRANT_TYPE,
  TABLEAU_URL: process.env.REACT_APP_TABLEAU_URL,
}

export default Config
