import React from 'react'
import OktaAuthentication from '../OktaAuthentication'
import EliteAuthProvider from '../EliteAuthProvider'
import AuthProvider from '../AuthProvider'

const AuthWrapper = ({ children }) => {
  return (
    <OktaAuthentication>
      <EliteAuthProvider>
        <AuthProvider>{children}</AuthProvider>
      </EliteAuthProvider>
    </OktaAuthentication>
  )
}

export default AuthWrapper
