// modules
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { NikeSwoosh } from '@nike/nike-design-system-icons'
import { ButtonStyled } from '@nike/nike-design-system-components'
import { accessibility, AdvancedImage } from '@cloudinary/react'
import { limitFit } from '@cloudinary/url-gen/actions/resize'
import { quality } from '@cloudinary/url-gen/actions/delivery'
import { useHistory } from 'react-router-dom'
import { TranslatableString as TS } from '@nike/i18n-react'
import { pathOr } from 'ramda'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade, Pagination, Parallax } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/effect-fade/effect-fade.min.css'

// lib
import { useCurrentUser } from 'components/UserProvider'
import myCld from 'lib/cloudinary'

// local
import styles from './SplashScreen.module.scss'
import './swiperCustom.css'
import { useOktaAuth } from '@okta/okta-react'

let backgroundImage = myCld
  .image('splash-background')
  .resize(limitFit().width(2400).height(1200))
  .delivery(quality('auto'))
  .addFlag('progressive')

const SplashScreen = () => {
  const { oktaAuth, authState } = useOktaAuth()
  const isOktaUser = !!(authState && authState.isAuthenticated)
  const isAuthenticated = isOktaUser

  // const login = oktaAuth.signInWithRedirect
  const login = () => oktaAuth.signInWithRedirect()
  const [parallaxOffset, setParallaxOffset] = useState(0)
  const imgRef = useRef()
  useEffect(() => {
    const handleResize = () => {
      // Hack: reach into the AdvancedImage component to get the width of the img element
      const imgWidth = pathOr(
        2400,
        ['current', 'imageRef', 'current', 'width'],
        imgRef,
      )
      setParallaxOffset(window.innerWidth - imgWidth)
    }

    if (window) {
      window.addEventListener('resize', handleResize)

      // Set initial size on load
      handleResize()

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [imgRef, setParallaxOffset])

  const [animationDone, setAnimationDone] = useState(false)
  const handleAnimationEnd = useCallback(() => {
    setAnimationDone(true)
  }, [setAnimationDone])

  const history = useHistory()
  const user = useCurrentUser()
  // const { isOktaUser, login } = useAuth()
  // const { oktaAuth } = useOktaAuth();

  // if (user) {
  //   if (isOktaUser) {
  //     history.push({
  //       pathname: '/admin',
  //     })
  //   }
  // }

  // const handleLogin = async () => {
  //   // Specify the options for the signInWithRedirect method
  //   const options = {
  //     originalUri: window.location.origin + '/admin', // The URL to redirect to after sign-in
  //     // Other options can be specified here
  //   };
  //
  //   // Start the sign-in process and redirect the user
  //   await oktaAuth.signInWithRedirect();
  // };

  return (
    <div className={styles.container}>
      <Swiper
        autoplay={{
          delay: 5000,
        }}
        pagination={{
          clickable: true,
        }}
        keyboard={{
          enabled: true,
        }}
        parallax={true}
        fadeEffect={{
          crossFade: true,
        }}
        effect={'fade'}
        modules={[EffectFade, Pagination, Parallax, Autoplay]}
        className={styles.swiper}
      >
        <AdvancedImage
          ref={imgRef}
          slot="container-start"
          className={styles.bgImage}
          cldImg={backgroundImage}
          plugins={[accessibility()]}
          data-swiper-parallax={parallaxOffset}
        />
        <div slot="container-start" className={styles.containerStart}>
          <NikeSwoosh className={styles.swoosh} />
          {/* remove from DOM after animating in so that user can interact with the swiper */}
          {animationDone ? (
            <div className={styles.welcomeContainer}>
              <div className={styles.bodyText}>
                <TS
                  primaryValue="Welcome to the Nike SPD Platform!"
                  stringKey="splash-screen-welcome"
                  description="Initial welcome message on the splash screen"
                  element={'p'}
                  className={styles.welcome}
                />
              </div>
            </div>
          ) : (
            <div
              className={styles.blackBackgroundFade}
              onAnimationEnd={handleAnimationEnd}
            />
          )}
        </div>
        <div slot="container-end" className={styles.containerEnd}>
          {/*<Link to="/login">*/}
          {/*  <ButtonStyled*/}
          {/*    className={styles.signInButton}*/}
          {/*    size="medium"*/}
          {/*    appearance="primary"*/}
          {/*    background="dark"*/}
          {/*  >*/}
          {/*    <TS*/}
          {/*      primaryValue="Sign in with Nike Okta"*/}
          {/*      stringKey="splash-screen-sign-in-button"*/}
          {/*      description="Sign in button on the splash screen"*/}
          {/*    />*/}
          {/*  </ButtonStyled>*/}
          {/*</Link>*/}
          <ButtonStyled
            className={styles.signInButton}
            size="medium"
            appearance="primary"
            background="dark"
            onClick={login}
          >
            <TS
              primaryValue="Sign in with Nike Okta"
              stringKey="splash-screen-sign-in-button-test"
              description="Sign in button on the splash screen test"
            />
          </ButtonStyled>
        </div>
        <SwiperSlide data-swiper-autoplay={animationDone ? 5000 : 7000}>
          <div className={styles.bodyText}>
            <TS
              primaryValue="Connect to Nike Services"
              stringKey="splash-screen-slide-1"
              description="First slide on the splash screen carousel"
              element={'p'}
              className={styles.services}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.bodyText}>
            <TS
              primaryValue="Share Your Insight"
              stringKey="splash-screen-slide-2"
              description="Second slide on the splash screen carousel"
              element={'p'}
              className={styles.insight}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.bodyText}>
            <TS
              primaryValue="Leave Your Legacy"
              stringKey="splash-screen-slide-3"
              description="Third slide on the splash screen carousel"
              element={'p'}
              className={styles.legacy}
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default React.memo(SplashScreen)
