import { gql } from '@apollo/client'

export const GET_CURRENT_USER_QUERY = gql`
  query getCurrentUser {
    whoami {
      id
      roles
      ... on User {
        upmId
        firstName
        lastName
        avatar {
          id
          url(
            input: {
              transforms: [
                {
                  width: "200"
                  height: "200"
                  crop: "fill"
                  gravity: "auto"
                  radius: "max"
                  background: "transparent"
                  format: "auto"
                  flags: "preserve_transparency"
                }
              ]
            }
          )
        }
        isAthlete
        athleteProfile {
          id
          firstName
          middleName
          lastName
          nameSuffix
          preferredFullName
          nicknames
          gender
          sport
          paralympicSport
          sports
          interests
          birthday {
            year
            month
            day
          }
          currentResidence
          hometown
          socialHandles {
            type
            handle
          }
          onboardingCompleted
          workWithKids
          familyInMedia
          email
          measurements {
            lifestyle {
              shoeSize {
                gender
                size
              }
              topSize {
                gender
                size
              }
              bottomSize {
                gender
                size
              }
            }
            training {
              shoeSize {
                gender
                size
              }
              topSize {
                gender
                size
              }
              bottomSize {
                gender
                size
              }
            }
            competition {
              shoeSize {
                gender
                size
              }
              topSize {
                gender
                size
              }
              bottomSize {
                gender
                size
              }
            }
          }
        }
      }
      ... on NikeEmployee {
        email
      }
    }
  }
`

export const SPORT_LIST_QUERY = gql`
  query sports {
    sports: __type(name: "Sport") {
      values: enumValues {
        value: name
        label: description
      }
    }
  }
`

export const PARALYMPIC_SPORT_LIST_QUERY = gql`
  query paralympics {
    paralympics: __type(name: "ParalympicSport") {
      values: enumValues {
        value: name
        label: description
      }
    }
  }
`

export const GENDER_LIST_QUERY = gql`
  query genders {
    genders: __type(name: "GenderIdentity") {
      values: enumValues {
        value: name
        label: description
      }
    }
  }
`

export const UPDATE_ATHLETE_PROFILE_SPORT = gql`
  mutation UpdateAthleteProfile(
    $sport: Sport
    $paralympicSport: ParalympicSport
  ) {
    updateAthleteProfile(
      input: { sport: $sport, paralympicSport: $paralympicSport }
    ) {
      athlete {
        id
        sport
        paralympicSport
      }
    }
  }
`

export const UPDATE_INTERESTS = gql`
  mutation UpdateInterests($athleteId: ID!, $interests: [String!]!) {
    updateAthleteInterests(
      input: { athleteId: $athleteId, interests: $interests }
    ) {
      athlete {
        id
        interests
      }
    }
  }
`

// ADMIN queries
export const ADMIN_GET_ATHLETE_QUERY = gql`
  query AdminGetAthlete($id: ID!) {
    node(id: $id) {
      ... on Athlete {
        id
        firstName
        middleName
        lastName
        nameSuffix
        nicknames
        preferredFullName
        pronouns
        gender
        birthday {
          year
          month
          day
        }
        age
        sport
        paralympicSport
        discipline
        currentResidence
        hometown
        tier
        interests
        communityCausesDescription
        personalMantra
        workWithKids
        familyInMedia
        email
        lastUpdated {
          by {
            id
            __typename
            ... on User {
              isAthlete
            }
          }
          date
        }
        socialHandles {
          type
          handle
        }
        measurements {
          lifestyle {
            shoeSize {
              gender
              size
            }
            topSize {
              gender
              size
            }
            bottomSize {
              gender
              size
            }
          }
          training {
            shoeSize {
              gender
              size
            }
            topSize {
              gender
              size
            }
            bottomSize {
              gender
              size
            }
          }
          competition {
            shoeSize {
              gender
              size
            }
            topSize {
              gender
              size
            }
            bottomSize {
              gender
              size
            }
          }
        }
        user {
          id
          avatar {
            id
            url(
              input: {
                transforms: [{ width: "600", height: "800", crop: "fit" }]
              }
            )
          }
        }
      }
    }
  }
`

export const GET_ADMIN_SURVEY_ANSWERS_FOR_ATHLETE = gql`
  query GetAdminSurveyAnswersForAthlete($id: ID!, $first: Int, $after: String) {
    node(id: $id) {
      ... on Athlete {
        id
        user {
          id
          surveyAnswers(input: { first: $first, after: $after }) {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                question {
                  id
                  prompt
                  choices {
                    name
                    value
                    percentageOfAnswers
                  }
                  totalResponses
                }
                choice {
                  name
                  value
                }
                #date # adding this now
              }
            }
          }
        }
      }
    }
  }
`

export const ADMIN_SPORTS_FILTER_QUERY = gql`
  query GetAthleteSportsFilterList {
    searchAthletes {
      filterAggregations {
        sport {
          key
          count
        }
        paralympicSport {
          key
          count
        }
      }
    }
  }
`

export const ADMIN_AGES_FILTER_QUERY = gql`
  query GetAthleteAgesFilterList {
    searchAthletes {
      filterAggregations {
        age {
          key
          count
        }
      }
    }
  }
`

export const ADMIN_LOCATIONS_FILTER_QUERY = gql`
  query GetAthleteLocationsFilterList($search: String) {
    searchAthletes {
      filterAggregations {
        location(include: $search) {
          key
          count
        }
      }
    }
  }
`

export const ADMIN_INTERESTS_FILTER_QUERY = gql`
  query GetAthleteInterestssFilterList($search: String) {
    searchAthletes {
      filterAggregations {
        interests(include: $search) {
          key
          count
        }
      }
    }
  }
`

export const SEARCH_ATHLETES_QUERY = gql`
  query SearchAthletes(
    $first: Int
    $after: String
    $search: String
    $filters: SearchAthleteFilterInput
  ) {
    searchAthletes(
      input: {
        first: $first
        after: $after
        search: $search
        filters: $filters
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          firstName
          middleName
          lastName
          nameSuffix
          tier
          sport
          paralympicSport
          interests
          age
          parent
          preferredFullName
          currentResidence
          birthday {
            year
            month
            day
          }
          user {
            id
            avatar {
              id
              url(
                input: {
                  transforms: [
                    {
                      width: "600"
                      height: "800"
                      crop: "fill"
                      gravity: "auto"
                    }
                  ]
                }
              )
            }
          }
        }
      }
    }
  }
`

export const SYNC_ELITE_USER = gql`
  mutation SyncEliteUser(
    $uuid: String!
    $accessToken: String!
    $username: String!
  ) {
    syncEliteUser(
      input: { uuid: $uuid, accessToken: $accessToken, username: $username }
    ) {
      user {
        id
        upmId
        nuId
        firstName
        lastName
        locality
      }
      locker {
        sportsCategory
        country
        accountTypeCode
        accountSubTypeCode
        assetType
        status
        primaryStatus
      }
    }
  }
`

export const GET_POLLS_QUERY = gql`
  query ListSurveys($first: Int, $after: String) {
    listSurveys(input: { first: $first, after: $after }) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          status
          totalResponses
          handle
          categories {
            name
            questions {
              totalResponses
              prompt
              questionType
              choices {
                percentageOfAnswers
                value
                name
              }
            }
          }
        }
      }
    }
  }
`

export const GET_POLL_QUERY = gql`
  query getSurvey($handle: String) {
    getSurvey(input: { handle: $handle }) {
      survey {
        id
        status
        totalResponses
        handle
        categories {
          name
          questions {
            totalResponses
            prompt
            questionType
            choices {
              percentageOfAnswers
              value
              name
            }
          }
        }
      }
      edges {
        node {
          user {
            id
          }
          question {
            id
          }
          choice {
            value
          }
          date
        }
      }
    }
  }
`

export const UPDATE_ATHLETE_HANDLES = gql`
  mutation UpdateProfile($socialHandles: [SocialLinkInput!]) {
    updateAthleteProfile(input: { socialHandles: $socialHandles }) {
      athlete {
        id
        socialHandles {
          type
          handle
        }
      }
    }
  }
`
export const SURVEY_LIST_QUERY = gql`
  query listSurveys($first: Int, $after: String) {
    listSurveys(input: { first: $first, after: $after }) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          handle
          categories {
            name
            questions {
              id
              prompt
              questionType
              choices {
                name
                value
                percentageOfAnswers
              }
              answer {
                choice {
                  name
                  value
                }
              }
            }
          }
          status
          totalResponses
        }
      }
    }
  }
`

export const SUBMIT_SURVEY_ANSWER = gql`
  mutation submitSurveyAnswer(
    $surveyId: ID!
    $questionId: String!
    $value: String!
  ) {
    submitSurveyAnswer(
      input: { surveyId: $surveyId, questionId: $questionId, value: $value }
    ) {
      answer {
        user {
          id
        }
        question {
          id
          prompt
          choices {
            name
            value
          }
        }
        choice {
          name
          value
        }
      }
    }
  }
`

export const ONBOARDING_COMPLETED = gql`
  mutation OnboardingCompleted {
    onboardingCompleted {
      athlete {
        id
        firstName
        lastName
        email
        interests
        sport
        paralympicSport
        hasRegistered
        user {
          id
          upmId
          nuId
          isAthlete
          athleteProfile {
            id
          }
        }
      }
    }
  }
`
export const UPDATE_ATHLETE_PROFILE = gql`
  mutation UpdateProfile(
    $socialHandles: [SocialLinkInput!]
    $firstName: String
    $middleName: String
    $lastName: String
    $nameSuffix: String
    $preferredFullName: String
    $nicknames: [String]
    $birthday: FixedDateInput
    $currentResidence: String
    $hometown: String
    $sport: Sport
    $paralympicSport: ParalympicSport
    $email: EmailAddress
    $gender: GenderIdentity
  ) {
    updateAthleteProfile(
      input: {
        socialHandles: $socialHandles
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        nameSuffix: $nameSuffix
        preferredFullName: $preferredFullName
        nicknames: $nicknames
        birthday: $birthday
        currentResidence: $currentResidence
        hometown: $hometown
        sport: $sport
        paralympicSport: $paralympicSport
        email: $email
        gender: $gender
      }
    ) {
      athlete {
        id
        firstName
        middleName
        lastName
        nameSuffix
        preferredFullName
        nicknames
        gender
        birthday {
          year
          month
          day
        }
        socialHandles {
          type
          handle
        }
        sport
        paralympicSport
        currentResidence
        hometown
        email
      }
    }
  }
`

export const UPDATE_ATHLETE_PROFILE_PARENTHOOD = gql`
  mutation UpdateAthleteProfile($workWithKids: Int, $familyInMedia: Int) {
    updateAthleteProfile(
      input: { workWithKids: $workWithKids, familyInMedia: $familyInMedia }
    ) {
      athlete {
        id
        workWithKids
        familyInMedia
      }
    }
  }
`

export const UPDATE_ATHLETE_PROFILE_NAME = gql`
  mutation UpdateAthleteProfile(
    $firstName: String
    $middleName: String
    $lastName: String
    $nameSuffix: String
    $preferredFullName: String
    $nicknames: [String]
    $birthday: FixedDateInput
    $email: EmailAddress
    $measurements: MeasurementGroupsInput
  ) {
    updateAthleteProfile(
      input: {
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        nameSuffix: $nameSuffix
        preferredFullName: $preferredFullName
        nicknames: $nicknames
        birthday: $birthday
        email: $email
        measurements: $measurements
      }
    ) {
      athlete {
        id
        firstName
        middleName
        lastName
        nameSuffix
        preferredFullName
        nicknames
        birthday {
          year
          month
          day
        }
        email
        measurements {
          lifestyle {
            shoeSize {
              gender
              size
            }
            topSize {
              gender
              size
            }
            bottomSize {
              gender
              size
            }
          }
          training {
            shoeSize {
              gender
              size
            }
            topSize {
              gender
              size
            }
            bottomSize {
              gender
              size
            }
          }
          competition {
            shoeSize {
              gender
              size
            }
            topSize {
              gender
              size
            }
            bottomSize {
              gender
              size
            }
          }
        }
      }
    }
  }
`

export const UPDATE_ATHLETE_ONBOARDING_COMPLETED = gql`
  mutation {
    onboardingCompleted {
      athlete {
        id
        onboardingCompleted
      }
    }
  }
`

export const ADMIN_GET_ATHLETE_BY_USER_ID = gql`
  query AdminGetAthleteByUserId($id: ID!) {
    node(id: $id) {
      ... on User {
        id
        avatar {
          id
          url(
            input: {
              transforms: [{ width: "600", height: "800", crop: "fit" }]
            }
          )
        }
        athleteProfile {
          id
          firstName
          middleName
          lastName
          nameSuffix
          nicknames
          preferredFullName
          pronouns
        }
      }
    }
  }
`

export const GET_UPCOMING_BIRTHDAYS = gql`
  query GetUpcomingBirthdays($first: Int, $after: String) {
    getUpcomingAthleteBirthdays(input: { first: $first, after: $after }) {
      edges {
        node {
          id
          firstName
          lastName
          age
          currentResidence
          birthday {
            day
            month
            year
          }
          user {
            avatar {
              url
            }
          }
        }
      }
    }
  }
`

// BuyList, view details and get download link
export const VIEW_BUYLIST = gql`
  query viewBuyList($input: ViewBuyListInput!) {
    createBuyList(input: $input) {
      input {
        user_email
        buyListCreated_key
        buyListCreated_URL
      }
    }
  }
`

export const GET_USER_UPLOADS = gql`
  query getUserUploads($contact: String) {
    getUserUploads(input: { contact: contact }) {
      myResult {
        test
      }
    }
  }
`
