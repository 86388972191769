import React, { useContext } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { pathOr } from 'ramda'

const AuthContext = React.createContext(null)

const AuthProvider = React.memo(({ children }) => {
  console.log('Loadin auth provider')
  const { oktaAuth, authState } = useOktaAuth()
  const isOktaUser = !!(authState && authState.isAuthenticated)
  const isAuthenticated = isOktaUser

  // const login = oktaAuth.signInWithRedirect
  const login = () => oktaAuth.signInWithRedirect()
  const logout = () => {
    if (isOktaUser) {
      oktaAuth.signOut()
    }
  }
  const getAccessToken = oktaAuth.getAccessToken
  const identity = pathOr(null, ['idToken', 'claims'], authState)

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isOktaUser,
        login,
        logout,
        getAccessToken,
        identity,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
})

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export default AuthProvider
