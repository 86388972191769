/*
 * This code was generated. Do not make changes manually since regeneration will remove those changes.
 *
 * To create enums for wildcarded fields, edit analytics.suggested-values.json
 *
 * Why are there top-level DETAIL_ enums? So the developer can quickly type PageEvents.EventName.DETAL_FOO instead of
 * PageEvents..properties.view.pageDetail.FOO (which still works).
 *
 */

export const Config = {
  SCHEMA_VERSION: 'LATEST',
  EXPERIENCE: 'athp',
  DIGITAL_PRODUCT: 'labs-to-the-world',
  DOMAIN: 'athp',
  PLATFORM: 'web',
  appConstants: {
    app: {
      build: 'cloud',
      domain: 'athp',
      version: '1.0.0',
    },
    locale: {
      country: 'us',
      language: 'en-US',
    },
  },
  validateInProd: false,
  uniteEnabled: true,
}

export const PageEvents = {
  ContentPageViewed: {
    experienceType: 'athp',
    domain: 'athp',

    requiredProps: [
      'properties.eventName',
      'properties.view.pageDetail',
      'properties.view.pageName',
      'properties.view.pageType',
      'properties.consumer.athleteId',
    ],
    properties: {
      eventName: 'Content Page Viewed',
      view: {
        pageDetail: '',
        pageName: 'athp>content page>',
        pageType: 'content page',
      },
      consumer: {
        upmId: '',
        oktaId: '',
        athleteId: '',
        userId: '',
      },
    },
    meta: {},
  },
  OnboardingPageViewed: {
    experienceType: 'athp',
    domain: 'athp',

    requiredProps: [
      'properties.eventName',
      'properties.view.pageDetail',
      'properties.view.pageName',
      'properties.view.pageType',
      'properties.consumer.athleteId',
    ],
    properties: {
      eventName: 'Onboarding Page Viewed',
      view: {
        pageDetail: '',
        pageName: 'athp>onboarding page>',
        pageType: 'onboarding page',
      },
      consumer: {
        athleteId: '',
      },
    },
    meta: {},
  },
  ProfilePageViewed: {
    experienceType: 'athp',
    domain: 'athp',

    requiredProps: [
      'properties.eventName',
      'properties.view.pageDetail',
      'properties.view.pageName',
      'properties.view.pageType',
      'properties.consumer.athleteId',
    ],
    properties: {
      eventName: 'Profile Page Viewed',
      view: {
        pageDetail: '',
        pageName: 'athp>profile page>',
        pageType: 'profile page',
      },
      consumer: {
        athleteId: '',
      },
    },
    meta: {},
  },
}

export const TrackEvents = {
  AccountAuthenticated: {
    experienceType: 'athp',
    domain: 'athp',

    requiredProps: [
      'event',
      'properties.clickActivity',
      'properties.eventName',
      'properties.view.pageDetail',
      'properties.view.pageName',
      'properties.view.pageType',
    ],
    properties: {
      clickActivity: 'account-authenticated-',
      eventName: 'Account Authenticated',
      view: {
        pageDetail: '',
        pageName: 'athp>login page>',
        pageType: 'login page',
      },
    },
    meta: {},
  },
  AccountCreated: {
    experienceType: 'athp',
    domain: 'athp',

    requiredProps: [
      'event',
      'properties.clickActivity',
      'properties.eventName',
      'properties.view.pageDetail',
      'properties.view.pageName',
      'properties.view.pageType',
    ],
    properties: {
      clickActivity: 'account-created-',
      eventName: 'Account Created',
      view: {
        pageDetail: '',
        pageName: 'athp>login page>',
        pageType: 'login page',
      },
    },
    meta: {},
  },
  OnboardingComplete: {
    experienceType: 'athp',
    domain: 'athp',

    requiredProps: [
      'event',
      'properties.eventName',
      'properties.view.pageDetail',
      'properties.view.pageName',
      'properties.view.pageType',
    ],
    properties: {
      eventName: 'Onboarding Complete',
      view: {
        pageDetail: '',
        pageName: 'athp>onboarding page>',
        pageType: 'onboarding page',
      },
    },
    meta: {},
  },
  OnboardingLogout: {
    experienceType: 'athp',
    domain: 'athp',

    requiredProps: [
      'event',
      'properties.eventName',
      'properties.view.pageDetail',
      'properties.view.pageName',
      'properties.view.pageType',
    ],
    properties: {
      eventName: 'Onboarding Logout',
      view: {
        pageDetail: '',
        pageName: 'athp>onboarding page>',
        pageType: 'onboarding page',
      },
    },
    meta: {},
  },
  ProfileLogout: {
    experienceType: 'athp',
    domain: 'athp',

    requiredProps: [
      'event',
      'properties.eventName',
      'properties.view.pageDetail',
      'properties.view.pageName',
      'properties.view.pageType',
    ],
    properties: {
      eventName: 'Profile Logout',
      view: {
        pageDetail: '',
        pageName: 'athp>profile page>',
        pageType: 'profile page',
      },
    },
    meta: {},
  },
  ServiceClicked: {
    experienceType: 'athp',
    domain: 'athp',

    requiredProps: [
      'event',
      'properties.clickActivity',
      'properties.eventName',
      'properties.view.pageDetail',
      'properties.view.pageName',
      'properties.view.pageType',
    ],
    properties: {
      clickActivity: 'service-',
      eventName: 'Service Clicked',
      view: {
        pageDetail: 'today',
        pageName: 'athp>profile page>',
        pageType: 'profile page',
      },
    },
    meta: {},
  },
}

export const BackendEvents = {}

export const SharedProps = {}
