// modules
import React, { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { applyTo, path, pipe } from 'ramda'

// aliased
import SearchInput from 'components/SearchInput'
import { defaultProps, propTypes } from 'lib/react'

const SearchBox = ({ className, defaultValue, onSubmit }) => {
  const [value, setValue] = useState(defaultValue)

  const handleChange = useCallback(pipe(path(['target', 'value']), setValue), [
    setValue,
  ])

  const handleInputKeyDown = useCallback(
    (evt) => {
      if (evt.key === 'Enter') {
        onSubmit(evt.target.value)
      }
    },
    [onSubmit],
  )

  const handleBlur = useCallback(
    (evt) => {
      if (evt.target.value !== defaultValue) {
        onSubmit(evt.target.value)
      }
    },
    [defaultValue, onSubmit],
  )

  return (
    <SearchInput
      className={className}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleInputKeyDown}
      value={value}
    />
  )
}

export default applyTo(
  SearchBox,
  pipe(
    propTypes({
      className: PropTypes.string,
      defaultValue: PropTypes.string,
      onSubmit: PropTypes.func,
    }),
    defaultProps({
      defaultValue: '',
      onSubmit: () => {},
    }),
    memo,
  ),
)
