import {
  always,
  append,
  concat,
  converge,
  head,
  identity,
  ifElse,
  includes,
  join,
  lensProp,
  map,
  over,
  pipe,
  propOr,
  split,
  tail,
  toLower,
  toUpper,
  unless,
  when,
  without,
} from 'ramda'

export const isChecked = (key, val, obj) =>
  pipe(propOr([], key), includes(val))(obj)

export const toggleValue = (key, val, obj) =>
  pipe(
    over(
      lensProp(key),
      pipe(
        unless(identity, always([])),
        ifElse(includes(val), without([val]), append(val)),
      ),
    ),
  )(obj)

export const formatEnum = when(
  identity,
  pipe(
    toLower,
    split('_'),
    map(converge(concat, [pipe(head, toUpper), tail])),
    join(' '),
  ),
)
