// modules
import React, { memo } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import ApolloProvider from 'components/ApolloProvider'

// lib
import Progress from 'components/Progress'
import AdminLayout from 'components/AdminLayout'
import { useAuth } from 'components/AuthProvider'

// local
import AdminProfileView from 'views/AdminViews/views/AdminProfileView'
import Analytics from 'views/AdminViews/views/Analytics'
import ListAthletes from 'views/AdminViews/views/ListAthletes'
import ListPolls from 'views/AdminViews/views/ListPolls'
import AthleteProfileView from './views/AthleteProfileView'
import AdminPollView from './views/AdminPollView'
import AdminHome from './views/AdminHome'

export default memo(() => {
  const { isAuthenticated } = useAuth()
  let { path } = useRouteMatch()

  if (!isAuthenticated) return <Progress />

  return (
    <ApolloProvider>
      <AdminLayout>
        <Switch>
          <Redirect exact from={`${path}`} to={`${path}/home`} />
          <Route exact path={`${path}/home`} component={AdminHome} />
          <Route path={`${path}/profile`} component={AdminProfileView} />
          <Route path={`${path}/analytics`} component={Analytics} />
          <Route exact path={`${path}/athletes`} component={ListAthletes} />
          <Route path={`${path}/athletes/:id`} component={AthleteProfileView} />
          <Route exact path={`${path}/polls`} component={ListPolls} />
          <Route path={`${path}/polls/:handle`} component={AdminPollView} />
          <Route
            path={`${path}/geo_dashboard`}
            to={
              'https://nike-sole-react.cloud.databricks.com/dashboardsv3/01eee0d9b3b11212b4cbc55fe236e318/published?o=554179923494093'
            }
          />
        </Switch>
      </AdminLayout>
    </ApolloProvider>
  )
})
