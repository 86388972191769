import { useQuery } from '@apollo/client'

import Progress from 'components/Progress'
import { GET_POLL_QUERY } from 'lib/graphql/query'

import styles from './AdminPollView.module.scss'
import { useHistory, useParams } from 'react-router-dom'
import Error from 'components/Error'
import PollChoice from 'components/Poll/PollChoice'
import AthletePollResponse from './AthletePollResponse'
import { ReactComponent as ArrowForward } from 'icons/general/arrow_forward.svg'
import { Text } from '@nike/nike-design-system-components'
import AthletePollHeader from './AthletePollHeader'

export default function AdminPollView() {
  const history = useHistory()
  const { handle } = useParams()

  const { loading, data } = useQuery(GET_POLL_QUERY, {
    errorPolicy: 'all',
    variables: {
      handle,
    },
  })

  // console.log({ data })

  if (loading) return <Progress />

  if (!data) return <Error />

  const answers = data.getSurvey.edges.filter((x) => x)
  const question = data?.getSurvey?.survey?.categories[0]?.questions[0]

  const { prompt = '', totalResponses = 0, choices = [] } = question || {}

  return (
    <div className={styles.container}>
      <div className={styles.navContainer}>
        <div className={styles.backContainer}>
          <div className={styles.nav}>
            <span className={styles.backButton} onClick={history.goBack}>
              <ArrowForward />
            </span>
          </div>
        </div>
        <div className={styles.topRightActionButtonsContainer}>
          {/* TODO: PUT EDIT AND EXPORT BUTTONS HERE */}
        </div>
      </div>

      <Text font="title-1" className={styles.title}>
        <span className={styles.pollsTitle}>{'Polls /'}</span> {prompt}
      </Text>

      <div className={styles.choices}>
        {choices.map((choice) => (
          <PollChoice key={choice.value} choice={choice} />
        ))}
      </div>
      <AthletePollHeader prompt={prompt} />
      {/* user answers */}
      {answers.map(
        ({
          node,
          node: {
            user: { id },
            choice: { value },
          },
        }) => (
          <AthletePollResponse
            {...node}
            key={`${id}-${value}`}
            choice={choices.find((c) => c.value == value)}
          />
        ),
      )}
    </div>
  )
}
