// modules
import { gql } from '@apollo/client'

// User

export const UPDATE_USER_AVATAR = gql`
  mutation UpdateAvatar($input: UpdateAvatarInput!) {
    updateAvatar(input: $input) {
      user {
        id
        ... on User {
          avatar {
            id
            url(
              input: {
                transforms: [
                  {
                    width: "200"
                    height: "200"
                    crop: "fill"
                    gravity: "auto"
                    radius: "max"
                    background: "transparent"
                    format: "auto"
                    flags: "preserve_transparency"
                  }
                ]
              }
            )
          }
        }
      }
    }
  }
`

// Files

export const GET_SIGNED_URLS_MUTATION = gql`
  mutation SignFileUploads($input: SignFileUploadsInput!) {
    signFileUploads(input: $input) {
      files {
        signedUrl
        file {
          id
        }
      }
    }
  }
`

// Athlete

export const REGISTER = gql`
  mutation register {
    register {
      athlete {
        id
        firstName
        middleName
        lastName
        nameSuffix
        preferredFullName
        nicknames
        sport
        paralympicSport
        sports
        interests
        birthday {
          year
          month
          day
        }
        currentResidence
        hometown
        socialHandles {
          type
          handle
        }
        onboardingCompleted
        user {
          id
          upmId
          nuId
          isAthlete
          athleteProfile {
            id
          }
        }
      }
    }
  }
`

export const UPDATE_ATHLETE_LOCATION = gql`
  mutation UpdateAthleteProfile($input: UpdateAthleteProfileInput!) {
    updateAthleteProfile(input: $input) {
      athlete {
        id
        currentResidence
        hometown
      }
    }
  }
`
// BuyList
export const CREATE_BUYLIST = gql`
  mutation createBuyList($input: CreateBuyListInput!) {
    createBuyList(input: $input) {
      files {
        buyListCreated
        buyListCreated_key
        buyListCreated_URL
      }
    }
  }
`

export const GET_USER_FILES = gql`
  mutation getUserFiles($input: UserFilesInput!) {
    getUserFiles(input: $input) {
      files {
        url
        name
      }
    }
  }
`
