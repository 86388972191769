// modules
import React, { useCallback } from 'react'
import { assoc, filter, isEmpty, not, pipe, propEq, propOr } from 'ramda'
import { useHistory, useLocation } from 'react-router-dom'
import { parse, serialize } from '@vl/js-lib/browser/url/querystring'

// aliased
import { search } from 'lib/adminSearch'

// local
import SearchBox from '../SearchBox'
import FilterList from '../FilterList'
import styles from './Header.module.scss'

const getDefaultValues = (location) => {
  // Update search when on the athlete list page
  if (propEq('pathname', '/admin/athletes', location)) {
    pipe(propOr('', 'search'), parse, search)(location)
  }

  return search()
}

const buildSearchQS = pipe(filter(pipe(isEmpty, not)), serialize)

const Header = () => {
  const location = useLocation()
  const defaultValues = getDefaultValues(location)
  const history = useHistory()
  const handleSearchSubmit = useCallback(
    (val) => {
      history.push({
        pathname: '/admin/athletes',
        search: buildSearchQS(assoc('search', val, defaultValues)),
      })
    },
    [defaultValues, history],
  )

  const handleFilterSubmit = useCallback(
    (val) => {
      history.push({
        pathname: '/admin/athletes',
        search: buildSearchQS(assoc('filters', val, defaultValues)),
      })
    },
    [defaultValues, history],
  )

  return (
    <></>
    //   <div className={styles.container}>
    //     <SearchBox
    //       className={styles.search}
    //       defaultValue={propOr('', 'search', defaultValues)}
    //       onSubmit={handleSearchSubmit}
    //     />
    //
    //     <FilterList
    //       className={styles.filters}
    //       defaultValue={propOr({}, 'filters', defaultValues)}
    //       onSubmit={handleFilterSubmit}
    //     />
    //   </div>
  )
}

export default React.memo(Header)
