// modules
import React, { memo, useContext } from 'react'
import PropTypes from 'prop-types'
import { applyTo, pipe } from 'ramda'
import { Search } from '@nike/nike-design-system-icons'
import { NikeI18nContext } from '@nike/i18n-react'
import cx from 'classnames'

// aliased
import { propTypes } from 'lib/react'

// local
import styles from './SearchInput.module.scss'

const SearchInput = ({ className, ...extra }) => {
  const { i18nString } = useContext(NikeI18nContext)

  return (
    <div className={cx(styles.container, className)}>
      <Search className={styles.icon} />
      <input
        className={styles.input}
        type="search"
        placeholder={i18nString({
          primaryValue: 'Search',
          stringKey: 'search-input-label',
          description: 'Placeholder text for a search input box',
        })}
        incremental={true}
        {...extra}
      />
    </div>
  )
}

export default applyTo(
  SearchInput,
  pipe(
    propTypes({
      className: PropTypes.string,
    }),
    memo,
  ),
)
