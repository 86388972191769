// modules
import React, { memo, useEffect } from 'react'
import { Route, Switch, useHistory, withRouter } from 'react-router-dom'

import { applyTo, pipe } from 'ramda'
import {
  LoginCallback as OktaLoginCallback,
  SecureRoute as OktaSecureRoute,
} from '@okta/okta-react'

// lib
import ProtectedView from 'views/ProtectedView'
import Error from 'components/Error'
import Logout from 'components/Logout'
import { defaultProps, propTypes } from 'lib/react'
import AdminViews from 'views/AdminViews'
// import Login from 'views/Login'
import Login from 'components/Login'
import SplashScreen from 'views/SplashScreen'

const App = applyTo(() => {
  const history = useHistory()

  useEffect(() => {
    return history.listen(() => {
      // Reset scroll position on page change
      window.scrollTo(0, 0)
    })
  }, [history])

  return (
    <Switch>
      <Route path="/login" exact={true} component={Login} />
      <Route path="/logout" exact={true} component={Logout} />
      <Route path="/oidc/logout" exact={true} component={Logout} />
      <Route path="/" exact={true} component={SplashScreen} />
      <Route
        path="/admin/callback"
        // eslint-disable-next-line react/jsx-no-bind
        component={(props) => {
          console.log('OktaLoginCallback rendered', props)
          return <OktaLoginCallback {...props} errorComponent={Error} />
        }}
      />
      <OktaSecureRoute path="/admin" component={AdminViews} />
      <Route path="/" component={ProtectedView} />
    </Switch>
  )
}, pipe(propTypes({}), defaultProps({}), memo))

export default withRouter(App)
