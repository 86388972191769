import Config from 'conf'

export default function Analytics() {
  return (
    <tableau-viz
      id="tableauViz"
      src={Config.TABLEAU_URL}
      device="desktop"
      toolbar="hidden"
      hide-tabs
    ></tableau-viz>
  )
}
