import { gql } from '@apollo/client'

export const typeDefs = gql`
  extend type Query {
    authToken: String
    orderBySlug: [Order]
    orderByDateRange: [Order]
    getProjectBySlug: Project!
    # getAllProjects: Portfolio
  }
`
