// modules
import React, { useCallback, useEffect, useState } from 'react'
import {
  CaretLeft as PrevPage,
  CaretRight as NextPage,
} from '@nike/nike-design-system-icons'
import { TranslatableString as TS } from '@nike/i18n-react'
import PropTypes from 'prop-types'
import { applyTo, not, pipe } from 'ramda'
import cx from 'classnames'

// lib
import Progress from 'components/Progress'
import { defaultProps, propTypes } from 'lib/react'

// local
import styles from './Pagination.module.scss'

const Pagination = ({
  currentPage,
  totalPages,
  loading,
  hasPreviousPage,
  hasNextPage,
  onPageChange,
}) => {
  const [pageInput, setPageInput] = useState(currentPage)

  useEffect(() => {
    // reset pageInput when currentPage changes
    setPageInput(currentPage)
  }, [currentPage, setPageInput])

  const handlePreviousPage = useCallback(() => {
    if (hasPreviousPage) {
      onPageChange(currentPage - 1)
    }
  }, [currentPage, hasPreviousPage, onPageChange])

  const handleNextPage = useCallback(() => {
    if (hasNextPage) {
      onPageChange(currentPage + 1)
    }
  }, [currentPage, hasNextPage, onPageChange])

  const handleInputFocus = useCallback(() => {
    setPageInput('')
  }, [setPageInput])

  const handleInputKeyDown = useCallback(
    (evt) => {
      if (evt.key === 'Enter') {
        onPageChange(parseInt(evt.target.value))
      }
    },
    [onPageChange],
  )

  const handleInputChange = useCallback(
    (evt) => {
      setPageInput(evt.target.value)
    },
    [setPageInput],
  )

  return (
    <div className={styles.paginateRow}>
      {loading && (
        <div className={styles.paginateLoading}>
          <Progress />
        </div>
      )}

      <PrevPage
        className={cx({ [styles.disabledIcon]: not(hasPreviousPage) })}
        type="button"
        disabled={not(hasPreviousPage)}
        onClick={handlePreviousPage}
        ariaLabel="next data page button"
      />

      <div className={styles.pagesRow}>
        <input
          type="number"
          min="1"
          max={totalPages}
          step={1}
          onFocus={handleInputFocus}
          onKeyDown={handleInputKeyDown}
          onChange={handleInputChange}
          className={styles.paginationPageEntry}
          value={pageInput}
          placeholder={currentPage}
        />
        {totalPages && (
          <p>
            <TS
              stringKey="pagination-of-total"
              primaryValue="of {total}"
              description="Indicates the total number of pages within the pagination control. Prefixed with an input to control current page. Example: 1 of 4"
              icuTokens={{
                total: totalPages,
              }}
            />
          </p>
        )}
      </div>

      <NextPage
        className={cx({ [styles.disabledIcon]: not(hasNextPage) })}
        type="button"
        onClick={handleNextPage}
        ariaLabel="previous data page button"
      />
    </div>
  )
}

export default applyTo(
  Pagination,
  pipe(
    propTypes({
      currentPage: PropTypes.number.isRequired,
      hasNextPage: PropTypes.bool,
      hasPreviousPage: PropTypes.bool,
      loading: PropTypes.bool,
      onPageChange: PropTypes.func.isRequired,
      totalPages: PropTypes.number,
    }),
    defaultProps({
      hasNextPage: false,
      hasPreviousPage: false,
      loading: false,
    }),
    React.memo,
  ),
)
