// modules
import React, { memo, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  always,
  applyTo,
  concat,
  converge,
  equals,
  head,
  ifElse,
  isEmpty,
  join,
  length,
  map,
  mergeLeft,
  pair,
  path,
  pipe,
  prop,
  propOr,
  when,
} from 'ramda'
import { TranslatableString as TS, NikeI18nContext } from '@nike/i18n-react'
import { CaretDown } from '@nike/nike-design-system-icons'
import cx from 'classnames'

// aliased
import { formatEnum } from 'lib/filters'
import { defaultProps, propTypes } from 'lib/react'

// local
import AgeFilter from './AgeFilter'
import InterestFilter from './InterestFilter'
import LocationFilter from './LocationFilter'
import SportFilter from './SportFilter'
import styles from './FilterList.module.scss'

const getFilters = (i18nString) => [
  {
    id: 'location',
    label: i18nString({
      stringKey: 'admin-filter-location-label',
      primaryValue: 'Locations',
      description:
        'Label for the locations filter on the admin athlete search page',
    }),
    component: LocationFilter,
  },
  {
    id: 'sport',
    label: i18nString({
      stringKey: 'admin-filter-sport-label',
      primaryValue: 'Sports',
      description:
        'Label for the sports filter on the admin athlete search page',
    }),
    component: SportFilter,
    getValueList: pipe(
      converge(concat, [propOr([], 'sport'), propOr([], 'paralympicSport')]),
      map(formatEnum),
    ),
  },
  {
    id: 'age',
    label: i18nString({
      stringKey: 'admin-filter-age-label',
      primaryValue: 'Ages',
      description:
        'Label for the age range filter on the admin athlete search page',
    }),
    component: AgeFilter,
    getValueList: pipe(
      propOr([], 'age'),
      map((ageRange) =>
        i18nString({
          stringKey: 'admin-filter-age-range-label',
          primaryValue: '{range} year olds',
          description:
            'Age ranges listed on admin athlete search page. Example: 18-24 year olds',
          icuTokens: {
            range: ageRange,
          },
        }),
      ),
    ),
  },
  {
    id: 'interests',
    label: i18nString({
      stringKey: 'admin-filter-interests-label',
      primaryValue: 'Interests',
      description:
        'Label for the interests filter on the admin athlete search page',
    }),
    component: InterestFilter,
  },
]

const getButtonLabel = ({ id, getValueList, label }) =>
  pipe(
    getValueList || propOr([], id),
    ifElse(
      isEmpty,
      always(
        <TS
          stringKey="admin-filter-all-selected-label"
          primaryValue="All {filter}"
          description="Label for a filter when all values are selected. Examples: All Sports, All Ages"
          icuTokens={{
            filter: label,
          }}
          className={styles.all}
        />,
      ),
      ifElse(
        pipe(length, equals(1)),
        head,
        converge(pipe(pair, join(' ')), [length, always(label)]),
      ),
    ),
  )

const FilterList = ({ className, defaultValue, onSubmit }) => {
  const { i18nString } = useContext(NikeI18nContext)
  const FILTERS = useMemo(() => getFilters(i18nString), [i18nString])
  const [activeFilter, setActiveFilter] = useState(null)
  const [value, setValue] = useState(defaultValue)

  const handleFilterClick = useCallback(
    pipe(
      path(['currentTarget', 'dataset', 'id']),
      when(equals(activeFilter), always(null)),
      setActiveFilter,
    ),
    [activeFilter, setActiveFilter],
  )

  const handleDismiss = useCallback(() => {
    if (value !== defaultValue) {
      onSubmit(value)
    }
    setActiveFilter(null)
  }, [defaultValue, onSubmit, setActiveFilter, value])

  const handleChange = useCallback(pipe(mergeLeft, setValue), [
    activeFilter,
    setValue,
  ])

  return (
    <>
      <div className={cx(styles.container, className)}>
        {FILTERS.map((aFilter) => (
          <button
            key={prop('id', aFilter)}
            className={styles.filter}
            data-id={prop('id', aFilter)}
            onClick={handleFilterClick}
          >
            {getButtonLabel(aFilter)(value)}
            <CaretDown />
          </button>
        ))}
      </div>
      <>
        {FILTERS.map(({ component: Component, id, label }) =>
          activeFilter === id ? (
            <Component
              key={id}
              label={label}
              onChange={handleChange}
              onDismiss={handleDismiss}
              isOpen={true}
              value={value}
            />
          ) : null,
        )}
      </>
    </>
  )
}

export default applyTo(
  FilterList,
  pipe(
    propTypes({
      className: PropTypes.string,
      defaultValue: PropTypes.shape({}),
      onSubmit: PropTypes.func,
    }),
    defaultProps({
      defaultValue: {},
      onSubmit: () => {},
    }),
    memo,
  ),
)
