import { omit, path, propOr } from 'ramda'
import { formatPageEvent, formatTrackEvent } from './analytics'
import { produceMessage } from '../lib/nsp/produceMessage'

const formatUserProps = (additionalProps = {}) => {
  const user = {
    athleteId: path(['athleteProfile', 'id'], additionalProps.user),
    consumer: {
      upmId: propOr(null, 'upmId', additionalProps.user),
      oktaId: propOr(null, 'oktaId', additionalProps.user),
    },
  }

  return {
    ...omit(['user'], additionalProps),
    ...user,
  }
}

// TODO: When removing the second Segment client, delete updateAnalyticsWriteKey
// const updateAnalyticsWriteKey = (key) => {
//   document.getElementById('segment_script').outerHTML = ''
//   window.analytics.load = function (t, e) {
//     var n = document.createElement('script')
//     n.id = 'segment_script'
//     n.type = 'text/javascript'
//     n.async = !0
//     n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js'
//     var a = document.getElementsByTagName('script')[0]
//     a.parentNode.insertBefore(n, a)
//     window.analytics._loadOptions = e
//   }
//   window.analytics.load(key)
// }

export const sendPageEvent = (pageEvent, pageDetail, additionalProps = {}) => {
  const formattedEvent = formatPageEvent(
    pageEvent,
    pageDetail,
    formatUserProps(additionalProps),
  )

  window.analytics.page(formattedEvent.eventName, formattedEvent.formattedProps)
  // TODO: When removing the second Segment client, delete these lines (45 - 49)
  setTimeout(() => {
    // updateAnalyticsWriteKey(process.env.REACT_APP_ANALYTICS_SEGMENTKEY_VL)
    window.analytics.page(
      formattedEvent.eventName,
      formattedEvent.formattedProps,
    )
  }, 500)
  setTimeout(
    // updateAnalyticsWriteKey(process.env.REACT_APP_ANALYTICS_SEGMENTKEY),
    500,
  )
}

export const sendTrackEvent = (
  trackEvent,
  pageDetail,
  pageType = null,
  clickActivity = null,
  additionalProps = {},
  spdToken = null,
) => {
  const formattedEvent = formatTrackEvent(
    trackEvent,
    pageDetail,
    pageType,
    clickActivity,
    formatUserProps(additionalProps),
  )
  console.log('Start sendTrackEvent')
  console.log(formattedEvent)
  produceMessage(spdToken).catch((error) => {
    console.error('Error during produceMessage:', error)
  })
  // const { isAuthenticated, isOktaUser, identity } = useAuth()
  // const { data, loading } = useQuery(GET_CURRENT_USER_QUERY, {
  //   // skip: !isAuthenticated,
  //   errorPolicy: 'all',
  // })
  // let user =  {
  //       ...pick(['email', 'name', 'upmId'], identity || {}),
  //       ...propOr({}, 'whoami', data),
  //     }
  // console.log(user)

  // NOTE TO Sports Marketing: this line could be replaced when moving off of Segment
  // window.analytics.track(
  //   formattedEvent.eventName,
  //   formattedEvent.formattedProps,
  // )
  // TODO: When removing the second Segment client, delete these lines (73 - 80)
  // setTimeout(() => {
  //   // updateAnalyticsWriteKey(process.env.REACT_APP_ANALYTICS_SEGMENTKEY_VL)
  //   console.log("second event")
  //   // window.analytics.track(
  //   //   formattedEvent.eventName,
  //   //   formattedEvent.formattedProps,
  //   // )
  //   console.log(formattedEvent.eventName,
  //       formattedEvent.formattedProps
  //   )
  // }, 500)
  // setTimeout(
  //     console.log("third event"),
  //   // updateAnalyticsWriteKey(process.env.REACT_APP_ANALYTICS_SEGMENTKEY),
  //   500,
  // )
}
