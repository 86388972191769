// modules
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import {
  __,
  append,
  applyTo,
  concat,
  converge,
  curry,
  filter,
  head,
  identity,
  includes,
  join,
  length,
  map,
  not,
  path,
  pathOr,
  pipe,
  prop,
  split,
  tail,
  toUpper,
  trim,
  uniq,
  without,
} from 'ramda'
import { NikeI18nContext, TranslatableString as TS } from '@nike/i18n-react'
import {
  ButtonStyled,
  InputStyled,
  TextStyled,
} from '@nike/nike-design-system-components'
import cx from 'classnames'

// aliased
import PillButton from 'components/PillButton'
import { useCurrentUser } from 'components/UserProvider'
import { UPDATE_INTERESTS } from 'lib/graphql/query'
import { defaultProps, propTypes } from 'lib/react'

// local
import styles from './InterestsView.module.scss'
import { ReactComponent as PerformanceScienceIcon } from 'icons/interests/Performance science.svg'
import { ReactComponent as RecoveryIcon } from 'icons/interests/Recovery.svg'
import { ReactComponent as SportsResearchIcon } from 'icons/interests/Sports Research.svg'
import { ReactComponent as ProductInnovationIcon } from 'icons/interests/Product Innovation.svg'
import { ReactComponent as MentalTrainingIcon } from 'icons/interests/Mental Training for Performance.svg'
import { ReactComponent as NutritionIcon } from 'icons/interests/Nutrition.svg'
import { ReactComponent as MentalHealthIcon } from 'icons/interests/Mental Health.svg'
import { ReactComponent as SocialJusticeIcon } from 'icons/interests/Social Justice.svg'
import { ReactComponent as MusicIcon } from 'icons/interests/Music.svg'
import { ReactComponent as PhotographyIcon } from 'icons/interests/Photography.svg'
import { ReactComponent as BooksIcon } from 'icons/interests/Books.svg'
import { ReactComponent as ArtIcon } from 'icons/interests/Art.svg'
import { ReactComponent as FoodIcon } from 'icons/interests/Food.svg'
import { ReactComponent as FashionIcon } from 'icons/interests/Fashion.svg'
import { ReactComponent as TravelIcon } from 'icons/interests/Travel.svg'
import { ReactComponent as FamilyIcon } from 'icons/interests/Family.svg'
import { ReactComponent as GamingIcon } from 'icons/interests/Gaming.svg'
import { ReactComponent as ParenthoodIcon } from 'icons/interests/Parenthood.svg'
import { ReactComponent as KidsInSportsIcon } from 'icons/interests/Kids in Sports.svg'
import { ReactComponent as PersonalBrandingIcon } from 'icons/interests/Personal Branding.svg'
import { ReactComponent as PublicSpeakingIcon } from 'icons/interests/Public Speaking.svg'
import { ReactComponent as ContentCreationIcon } from 'icons/interests/Content Creation.svg'
import { ReactComponent as PodcastIcon } from 'icons/interests/Podcast.svg'
import { ReactComponent as AuthorIcon } from 'icons/interests/Author.svg'
import { ReactComponent as YouTubeIcon } from 'icons/interests/YouTube.svg'
import { ReactComponent as MediaTrainingIcon } from 'icons/interests/Media Training.svg'
import { ReactComponent as AdvisingIcon } from 'icons/interests/Advising.svg'
import { ReactComponent as InvestingIcon } from 'icons/interests/Investing.svg'
import { ReactComponent as BusinessCreationIcon } from 'icons/interests/Business Creation.svg'
import { ReactComponent as AthleteCommerceIcon } from 'icons/interests/Athlete Commerce.svg'
import { ReactComponent as CreatorCommerceIcon } from 'icons/interests/Creator Commerce.svg'
import { ReactComponent as EntrepreneurshipIcon } from 'icons/interests/Entrepreneurship.svg'
import { ReactComponent as LeadershipIcon } from 'icons/interests/Leadership.svg'
import { ReactComponent as TeamBuildingIcon } from 'icons/interests/Team Building.svg'
import { ReactComponent as CoachingIcon } from 'icons/interests/Coaching.svg'
import { ReactComponent as MentoringIcon } from 'icons/interests/Mentoring.svg'
import { ReactComponent as LGBTQIAPlusIcon } from 'icons/interests/LGBTQIA+.svg'
import { ReactComponent as DiversityIcon } from 'icons/interests/Diversity Equity and Inclusion.svg'
import { ReactComponent as SustainabilityIcon } from 'icons/interests/Sustainability.svg'
import { ReactComponent as NonprofitsIcon } from 'icons/interests/Non-profits.svg'
import { ReactComponent as CommunityBuildingIcon } from 'icons/interests/Community Building.svg'
import { ReactComponent as CommunityGrowthIcon } from 'icons/interests/Community Growth.svg'

// See https://nike.box.com/s/rssp06e8om21u67gx6d7lpcy0w7zxlto
export const INTEREST_OPTIONS = [
  // Athletes as Community
  {
    value: 'LGBTQIA+',
    label: (
      <TS
        stringKey="interest-opts-lgbtqiaPlus"
        primaryValue="LGBTQIA+"
        description="Interest input option value for LGBTQIA+"
      />
    ),
    icon: <LGBTQIAPlusIcon />,
  },
  {
    value: 'Diversity, Equity, and Inclusion',
    label: (
      <TS
        stringKey="interest-opts-diversityEquityInclusion"
        primaryValue="Diversity, Equity, and Inclusion"
        description="Interest input option value for Diversity, Equity, and Inclusion"
      />
    ),
    icon: <DiversityIcon />,
  },
  {
    value: 'Sustainability',
    label: (
      <TS
        stringKey="interest-opts-sustainability"
        primaryValue="Sustainability"
        description="Interest input option value for Sustainability"
      />
    ),
    icon: <SustainabilityIcon />,
  },
  {
    value: 'Non-profits',
    label: (
      <TS
        stringKey="interest-opts-nonprofits"
        primaryValue="Non-profits"
        description="Interest input option value for Non-profits"
      />
    ),
    icon: <NonprofitsIcon />,
  },
  {
    value: 'Community Building',
    label: (
      <TS
        stringKey="interest-opts-communityBuilding"
        primaryValue="Community Building"
        description="Interest input option value for Community Building"
      />
    ),
    icon: <CommunityBuildingIcon />,
  },
  {
    value: 'Community Growth',
    label: (
      <TS
        stringKey="interest-opts-communityGrowth"
        primaryValue="Community Growth"
        description="Interest input option value for Community Growth"
      />
    ),
    icon: <CommunityGrowthIcon />,
  },
  // Athletes as Athletes
  {
    value: 'Performance Science',
    label: (
      <TS
        primaryValue="Performance Science"
        stringKey="interest-opts-performanceScience"
        description="Interest input option value for Performance Science"
      />
    ),
    icon: <PerformanceScienceIcon />,
  },
  {
    value: 'Recovery',
    label: (
      <TS
        stringKey="interest-opts-recovery"
        primaryValue="Recovery"
        description="Interest input option value for Recovery"
      />
    ),
    icon: <RecoveryIcon />,
  },
  {
    value: 'Sports Research',
    label: (
      <TS
        stringKey="interest-opts-sportsResearch"
        primaryValue="Sports Research"
        description="Interest input option value for Sports Research"
      />
    ),
    icon: <SportsResearchIcon />,
  },
  {
    value: 'Product Innovation',
    label: (
      <TS
        stringKey="interest-opts-productInnovation"
        primaryValue="Product Innovation"
        description="Interest input option value for Product Innovation"
      />
    ),
    icon: <ProductInnovationIcon />,
  },
  {
    value: 'Mental Training for Performance',
    label: (
      <TS
        stringKey="interest-opts-mentalTraining"
        primaryValue="Mental Training for Performance"
        description="Interest input option value for Mental Training for Performance"
      />
    ),
    icon: <MentalTrainingIcon />,
  },
  {
    value: 'Nutrition',
    label: (
      <TS
        stringKey="interest-opts-nutrition"
        primaryValue="Nutrition"
        description="Interest input option value for Nutrition"
      />
    ),
    icon: <NutritionIcon />,
  },
  // Athletes as Humans
  {
    value: 'Mental Health',
    label: (
      <TS
        stringKey="interest-list-mental-health"
        primaryValue="Mental Health"
        description="Interest input option value for Mental Health"
      />
    ),
    icon: <MentalHealthIcon />,
  },
  {
    value: 'Social Justice',
    label: (
      <TS
        stringKey="interest-opts-socialJustice"
        primaryValue="Social Justice"
        description="Interest input option value for Social Justice"
      />
    ),
    icon: <SocialJusticeIcon />,
  },
  {
    value: 'Music',
    label: (
      <TS
        stringKey="interest-opts-music"
        primaryValue="Music"
        description="Interest input option value for Music"
      />
    ),
    icon: <MusicIcon />,
  },
  {
    value: 'Photography',
    label: (
      <TS
        stringKey="interest-opts-photography"
        primaryValue="Photography"
        description="Interest input option value for Photography"
      />
    ),
    icon: <PhotographyIcon />,
  },
  {
    value: 'Books',
    label: (
      <TS
        stringKey="interest-opts-books"
        primaryValue="Books"
        description="Interest input option value for Books"
      />
    ),
    icon: <BooksIcon />,
  },
  {
    value: 'Art',
    label: (
      <TS
        stringKey="interest-opts-art"
        primaryValue="Art"
        description="Interest input option value for Art"
      />
    ),
    icon: <ArtIcon />,
  },
  {
    value: 'Food',
    label: (
      <TS
        stringKey="interest-opts-food"
        primaryValue="Food"
        description="Interest input option value for Food"
      />
    ),
    icon: <FoodIcon />,
  },
  {
    value: 'Fashion',
    label: (
      <TS
        stringKey="interest-opts-fashion"
        primaryValue="Fashion"
        description="Interest input option value for Fashion"
      />
    ),
    icon: <FashionIcon />,
  },
  {
    value: 'Travel',
    label: (
      <TS
        stringKey="interest-opts-travel"
        primaryValue="Travel"
        description="Interest input option value for Travel"
      />
    ),
    icon: <TravelIcon />,
  },
  {
    value: 'Family',
    label: (
      <TS
        stringKey="interest-opts-family"
        primaryValue="Family"
        description="Interest input option value for Family"
      />
    ),
    icon: <FamilyIcon />,
  },
  {
    value: 'Gaming',
    label: (
      <TS
        stringKey="interest-opts-gaming"
        primaryValue="Gaming"
        description="Interest input option value for Gaming"
      />
    ),
    icon: <GamingIcon />,
  },
  {
    value: 'Parenthood',
    label: (
      <TS
        stringKey="interest-opts-parenthood"
        primaryValue="Parenthood"
        description="Interest input option value for Parenthood"
      />
    ),
    icon: <ParenthoodIcon />,
  },
  {
    value: 'Kids in Sports',
    label: (
      <TS
        stringKey="interest-opts-kidsInSports"
        primaryValue="Kids in Sports"
        description="Interest input option value for Kids in Sports"
      />
    ),
    icon: <KidsInSportsIcon />,
  },
  // Athletes as Brands
  {
    value: 'Personal Branding',
    label: (
      <TS
        stringKey="interest-opts-personalBranding"
        primaryValue="Personal Branding"
        description="Interest input option value for Personal Branding"
      />
    ),
    icon: <PersonalBrandingIcon />,
  },
  {
    value: 'Public Speaking',
    label: (
      <TS
        stringKey="interest-opts-publicSpeaking"
        primaryValue="Public Speaking"
        description="Interest input option value for Public Speaking"
      />
    ),
    icon: <PublicSpeakingIcon />,
  },
  {
    value: 'Content Creation',
    label: (
      <TS
        stringKey="interest-opts-contentCreation"
        primaryValue="Content Creation"
        description="Interest input option value for Content Creation"
      />
    ),
    icon: <ContentCreationIcon />,
  },
  {
    value: 'Podcasting',
    label: (
      <TS
        stringKey="interest-opts-podcasting"
        primaryValue="Podcasting"
        description="Interest input option value for Podcasting"
      />
    ),
    icon: <PodcastIcon />,
  },
  {
    value: 'YouTube',
    label: (
      <TS
        stringKey="interest-opts-youtube"
        primaryValue="YouTube"
        description="Interest input option value for YouTube"
      />
    ),
    icon: <YouTubeIcon />,
  },
  {
    value: 'Author',
    label: (
      <TS
        stringKey="interest-opts-author"
        primaryValue="Author"
        description="Interest input option value for Author"
      />
    ),
    icon: <AuthorIcon />,
  },
  {
    value: 'Media Training',
    label: (
      <TS
        stringKey="interest-opts-mediaTraining"
        primaryValue="Media Training"
        description="Interest input option value for Media Training"
      />
    ),
    icon: <MediaTrainingIcon />,
  },
  // Athletes as Businesses
  {
    value: 'Advising',
    label: (
      <TS
        stringKey="interest-opts-advising"
        primaryValue="Advising"
        description="Interest input option value for Advising"
      />
    ),
    icon: <AdvisingIcon />,
  },
  {
    value: 'Investing',
    label: (
      <TS
        stringKey="interest-opts-investing"
        primaryValue="Investing"
        description="Interest input option value for Investing"
      />
    ),
    icon: <InvestingIcon />,
  },
  {
    value: 'Business Creation',
    label: (
      <TS
        stringKey="interest-opts-businessCreation"
        primaryValue="Business Creation"
        description="Interest input option value for Business Creation"
      />
    ),
    icon: <BusinessCreationIcon />,
  },
  {
    value: 'Athlete Commerce',
    label: (
      <TS
        stringKey="interest-opts-athleteCommerce"
        primaryValue="Athlete Commerce"
        description="Interest input option value for Athlete Commerce"
      />
    ),
    icon: <AthleteCommerceIcon />,
  },
  {
    value: 'Creator Commerce',
    label: (
      <TS
        stringKey="interest-opts-creatorCommerce"
        primaryValue="Creator Commerce"
        description="Interest input option value for Creator Commerce"
      />
    ),
    icon: <CreatorCommerceIcon />,
  },
  {
    value: 'Entrepreneurship',
    label: (
      <TS
        stringKey="interest-opts-entrepreneurship"
        primaryValue="Entrepreneurship"
        description="Interest input option value for Entrepreneurship"
      />
    ),
    icon: <EntrepreneurshipIcon />,
  },
  // Athletes as Leaders
  {
    value: 'Leadership',
    label: (
      <TS
        stringKey="interest-opts-leadership"
        primaryValue="Leadership"
        description="Interest input option value for Leadership"
      />
    ),
    icon: <LeadershipIcon />,
  },
  {
    value: 'Team Building',
    label: (
      <TS
        stringKey="interest-opts-teamBuilding"
        primaryValue="Team Building"
        description="Interest input option value for Team Building"
      />
    ),
    icon: <TeamBuildingIcon />,
  },
  {
    value: 'Coaching',
    label: (
      <TS
        stringKey="interest-opts-coaching"
        primaryValue="Coaching"
        description="Interest input option value for Coaching"
      />
    ),
    icon: <CoachingIcon />,
  },
  {
    value: 'Mentoring',
    label: (
      <TS
        stringKey="interest-opts-mentoring"
        primaryValue="Mentoring"
        description="Interest input option value for Mentoring"
      />
    ),
    icon: <MentoringIcon />,
  },
]

const isOtherInterest = pipe(
  includes(__, map(prop('value'), INTEREST_OPTIONS)),
  not,
)

const parseOtherInterests = pipe(
  split(','),
  map(pipe(trim, converge(concat, [pipe(head, toUpper), tail]))),
  filter(identity),
  uniq,
)

const InterestsView = ({
  onComplete,
  hideContinueButton = false,
  fireSnack = () => {},
}) => {
  const user = useCurrentUser()
  const { i18nString } = useContext(NikeI18nContext)
  const [interests, setInterests] = useState(
    pathOr([], ['athleteProfile', 'interests'], user),
  )
  const [isOtherVisible, setOtherVisible] = useState(false)

  useEffect(() => {
    setInterests(pathOr([], ['athleteProfile', 'interests'], user))
  }, [user, setInterests])

  const [saveInterests, { loading }] = useMutation(UPDATE_INTERESTS, {
    errorPolicy: 'all',
  })

  const updateInterests = (newInterestsList) => {
    saveInterests({
      variables: {
        athleteId: path(['athleteProfile', 'id'], user),
        interests: newInterestsList,
      },
    })
    fireSnack(true)
  }

  const toggleInterest = curry((interest, interests) => {
    if (includes(interest, interests)) {
      const smallerList = without([interest], interests)
      updateInterests(smallerList)
      return smallerList
    }
    const largerInterestList = append(interest, interests)
    updateInterests(largerInterestList)
    fireSnack(true)
    return largerInterestList
  })

  const handleContinueClick = useCallback(async () => {
    await saveInterests({
      variables: {
        athleteId: path(['athleteProfile', 'id'], user),
        interests,
      },
    })
    onComplete()
    fireSnack(true)
  }, [interests, user, onComplete, saveInterests, fireSnack])

  const handleInterestClick = useCallback(
    (interest) =>
      applyTo(
        interest,
        pipe(
          path(['currentTarget', 'dataset', 'value']),
          toggleInterest,
          setInterests,
        ),
      ),
    [setInterests, toggleInterest],
  )

  const handleOtherClick = useCallback(() => {
    setOtherVisible(true)
    setOtherValue(join(', ', filter(isOtherInterest, interests)))
    // let it mount before querySelector
    setTimeout(
      () =>
        document
          .querySelector('#scroll-to-other-field-target')
          .scrollIntoView({ block: 'center' }),
      5,
    )
  }, [interests, setOtherVisible])

  const [otherValue, setOtherValue] = useState(
    join(', ', filter(isOtherInterest, interests)),
  )

  const otherInterests = parseOtherInterests(otherValue)

  // TODO: find a better solution than updating the backend on input change.  onBlur? How do we know when they're done typing?
  const handleOtherInputChange = (evt) => setOtherValue(evt.target.value)

  const handleSaveInterests = useCallback(
    (evt) => {
      const combinedInterests = pipe(
        parseOtherInterests,
        concat(filter(pipe(isOtherInterest, not), interests)),
        uniq,
      )(evt.target.value)
      saveInterests({
        variables: {
          athleteId: path(['athleteProfile', 'id'], user),
          interests: combinedInterests,
        },
      })
    },
    [interests, user, saveInterests],
  )

  if (isOtherVisible) {
    return (
      <div className={styles.container}>
        <div
          className={cx(styles.content, {
            [styles.scrollBox]: !hideContinueButton,
          })}
        >
          <InputStyled
            id="scroll-to-other-field-target"
            className={cx(styles.otherField, {
              [styles.darkBorder]: hideContinueButton,
            })}
            placeholder={i18nString({
              stringKey: 'onboarding-other-interests-placeholder',
              primaryValue: 'Help us discover new interests',
              description:
                'Input placeholder for the Other Interests field on the onboarding page',
            })}
            type="text"
            label={i18nString({
              stringKey: 'onboarding-other-interests-label',
              primaryValue: 'Please define other interests',
              description:
                'Label for the Other Interests input field on the onboarding page',
            })}
            value={otherValue}
            onBlur={handleSaveInterests}
            onChange={handleOtherInputChange}
          />
          <div className={cx({ [styles.otherWrapper]: hideContinueButton })}>
            {otherInterests.map((interest) => (
              <PillButton
                key={interest}
                data-value={interest}
                background="dark"
              >
                {interest}
              </PillButton>
            ))}
          </div>
        </div>

        {!hideContinueButton && (
          <ButtonStyled
            appearance={'primary'}
            background={'dark'}
            size={'medium'}
            className={styles.continueButton}
            onClick={onComplete}
          >
            <TS
              primaryValue="Continue"
              stringKey="onboarding-cta-continue"
              description="Onboarding page dynamic call to action continue button"
            />
          </ButtonStyled>
        )}
      </div>
    )
  }

  return (
    <div className={cx({ [styles.container]: !hideContinueButton })}>
      <div
        className={cx(styles.content, {
          [styles.scrollBox]: !hideContinueButton,
        })}
      >
        <TextStyled appearance="body2" className={styles.header}>
          <TS
            stringKey="onboarding-interests-description"
            primaryValue="Select interests that you identify with most"
            description="Description text for the Interests field on the onboarding page"
          />
        </TextStyled>
        {INTEREST_OPTIONS.map((option) => (
          <PillButton
            key={option.value}
            data-value={option.value}
            background="dark"
            iconPlacement="start"
            Icon={option.icon}
            selected={includes(option.value, interests)}
            onClick={handleInterestClick}
          >
            {option.label}
          </PillButton>
        ))}
        <PillButton
          background="dark"
          selected={length(otherInterests) > 0}
          onClick={handleOtherClick}
        >
          <TS
            primaryValue="Other"
            stringKey="interest-opts-other"
            description="Interest input option value for Other"
          />
        </PillButton>
      </div>
      {!hideContinueButton && (
        <ButtonStyled
          appearance={'primary'}
          background={'dark'}
          size={'medium'}
          className={styles.continueButton}
          onClick={handleContinueClick}
          disabled={loading}
        >
          <TS
            primaryValue="Continue"
            stringKey="onboarding-cta-continue"
            description="Onboarding page dynamic call to action continue button"
          />
        </ButtonStyled>
      )}
    </div>
  )
}

export default applyTo(
  InterestsView,
  pipe(
    propTypes({
      onComplete: PropTypes.func,
    }),
    defaultProps({
      onComplete: () => {},
    }),
    memo,
  ),
)
