// modules
import { makeVar as apolloMakeVar } from '@apollo/client'
import { isString } from '@vl/js-lib/browser/is'

const setValueInStorage = (config, value) =>
  config.storage.setItem(
    config.storageKey,
    isString(value) ? value : JSON.stringify(value),
  )

// temp fix until https://github.com/apollographql/apollo-client/pull/7148 is merged
export const makeVar = (value, config = {}) => {
  // set up reactive variable
  const rv = apolloMakeVar(value)

  // restore previous value from storage
  const restore = async () => {
    // Set reactiveVar to previous value from storage,
    // if there is no previous value, do nothing.
    try {
      const previousValue = await config.storage.getItem(config.storageKey)
      if (previousValue) {
        rv(isString(value) ? previousValue : JSON.parse(previousValue))
      }
    } catch {
      // pass
    }
  }

  // if options are passed, use wrapper function for updating value
  const rvWithOptions = (newValue) => {
    if (!newValue) return rv()

    // set new reactive variable value
    const newRvValue = rv(newValue)
    // if storage config passed, set new value in storage
    if (config.storage) setValueInStorage(config, newRvValue)

    return newRvValue
  }

  // restore previous value on init if persisted
  if (config.storage) restore()
  // if no additional config, return basic reactive variable
  if (!config) return rv

  return [rvWithOptions, restore]
}
