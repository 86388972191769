import React from 'react'
import { TranslatableString as TS } from '@nike/i18n-react'

import { useCurrentUser } from 'components/UserProvider'

import styles from './AdminProfileView.module.scss'

const AdminProfileView = () => {
  const user = useCurrentUser()
  return (
    <div className={styles.profileWrapper}>
      <div>
        <TS
          stringKey="profile-link-header-text"
          primaryValue="Profile"
          description="The header/link text for Profile"
        />
      </div>
      <div>
        <TS
          stringKey="name-field-prefix"
          primaryValue="Name"
          description="The name field prefix for user name in admin account profile view"
        />
        {': '}
        {user?.firstName} {user?.lastName}
      </div>
      {user?.email && (
        <div>
          <TS
            stringKey="admin-profile-email-field-prefix"
            primaryValue="Email: "
            description="The email field prefix for user email in admin account profile view"
          />
          {user?.email}
        </div>
      )}
    </div>
  )
}

export default AdminProfileView
