import React from 'react'
import PropTypes from 'prop-types'
import fetch from 'unfetch'
import { applyTo, path, pipe } from 'ramda'
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client'

import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/link-error'
import jwt_decode from 'jwt-decode' // eslint-disable-line
import { isPast } from 'date-fns'

import { typeDefs } from '../../lib/graphql/type'
import { propTypes } from '../../lib/react'
import config from '../../conf'
import { useAuth } from 'components/AuthProvider'

import { useOktaAuth } from '@okta/okta-react'

const isTokenExpired = pipe(
  jwt_decode,
  path(['exp']),
  (exp) => new Date(exp * 1000),
  isPast,
)

const httpLink = new HttpLink({
  uri: `${config.API_HOST}/graphql`,
  fetch,
})

const authMiddleware = (logout) =>
  new ApolloLink((operation, forward) => {
    const { accessToken } = operation.getContext()
    if (isTokenExpired(accessToken)) {
      logout()
    }
    operation.setContext({
      headers: {
        authorization: accessToken ? `Bearer ${accessToken}` : null,
      },
    })

    return forward(operation)
  })

const cache = new InMemoryCache()

export default applyTo(
  ({ children }) => {
    const { isAuthenticated, getAccessToken, logout, isOktaUser } = useAuth()
    // using  the passed down getAccessToken for okta throws a tokenManager error
    const { oktaAuth } = useOktaAuth()

    const withAccessToken = setContext(() => {
      if (isAuthenticated) {
        const accessToken = isOktaUser
          ? oktaAuth.getAccessToken()
          : getAccessToken()
        return { accessToken }
      }

      return null
    })

    const client = new ApolloClient({
      link: ApolloLink.from([
        withAccessToken,
        authMiddleware(logout),
        onError(({ graphQLErrors, networkError, operation }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
              console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
              )
            })
          }
          const status = path(['response', 'status'], operation.getContext())
          if (status === 401) {
            console.error('401 - Unauthorized')
            logout()
          }
          if (networkError) console.log(`[Network error]: ${networkError}`)
        }),
        httpLink,
      ]),
      cache,
      connectToDevTools: true,
      typeDefs,
    })

    return <ApolloProvider client={client}>{children}</ApolloProvider>
  },
  pipe(
    propTypes({
      children: PropTypes.node,
    }),
    React.memo,
  ),
)
