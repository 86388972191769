import React, { memo } from 'react'
import { applyTo, pipe } from 'ramda'
import PropTypes from 'prop-types'
import { Skeleton } from '@nike/nike-design-system-components'
import { NikeSwoosh } from '@nike/nike-design-system-icons'
import { defaultProps, propTypes } from 'lib/react'
import FlexCenter from 'components/FlexCenter'
import styles from './Progress.module.scss'

export default applyTo(
  ({ hidden, className, style }) => {
    if (hidden) return null
    return (
      <FlexCenter style={style} className={className}>
        <Skeleton className={styles.loader}>
          <NikeSwoosh className={styles.swoosh} />
        </Skeleton>
      </FlexCenter>
    )
  },
  pipe(
    propTypes({
      className: PropTypes.string,
    }),
    defaultProps({}),
    memo,
  ),
)
