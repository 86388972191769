// modules
import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { applyTo, pipe } from 'ramda'
import { withRouter } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import FlexCenter from 'components/FlexCenter'
import { defaultProps, propTypes } from 'lib/react'
// import { useNikeAuth } from 'components/NikeAuthProvider'
import { TranslatableString as TS } from '@nike/i18n-react'

import styles from './Login.module.scss'

export default applyTo(
  () => {
    const { oktaAuth } = useOktaAuth()

    const handleLogin = useCallback(() => {
      oktaAuth.signInWithRedirect({ originalUri: '/admin' })
    }, [oktaAuth])

    const handleNikeLogin = useCallback(() => {
      oktaAuth.signInWithRedirect({ originalUri: '/' })
    }, [oktaAuth])

    return (
      <FlexCenter className={styles.wrapper}>
        <div>
          <button variant="outlined" onClick={handleLogin}>
            <TS
              stringKey="login-page-admin-login-button"
              primaryValue="Admin Login"
              description="Login page Admin login button"
            />
          </button>
          <button variant="outlined" onClick={handleNikeLogin}>
            <TS
              stringKey="login-page-athlete-login-button"
              primaryValue="Athlete Login"
              description="Login page Athlete login button"
            />
          </button>
        </div>
      </FlexCenter>
    )
  },
  pipe(
    propTypes({
      title: PropTypes.string,
    }),
    defaultProps({
      title: 'Login',
    }),
    withRouter,
    memo,
  ),
)
