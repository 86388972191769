import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { applyTo, pipe } from 'ramda'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'

import { propTypes, defaultProps } from '../../lib/react'
import config from '../../conf'

const oktaAuth = new OktaAuth({
  issuer: config.OKTA_URL,
  clientId: config.OKTA_CLIENT_ID,
  redirectUri: config.OKTA_REDIRECT_URI,
  pkce: true,
  postLogoutRedirectUri: config.ROOT_URL,
})

export default applyTo(
  ({ children }) => {
    console.log('Loading OktaAuth')
    const history = useHistory()

    const restoreOriginalUri = useCallback(
      async (_oktaAuth, originalUri) => {
        console.log('restoreOriginalUri called', originalUri)
        history.replace(
          toRelativeUrl(originalUri || '/admin', window.location.origin),
        )
      },
      [history],
    )

    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        {children}
      </Security>
    )
  },
  pipe(
    propTypes({
      children: PropTypes.node,
    }),
    defaultProps({
      children: null,
    }),
    memo,
  ),
)
