// modules
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

// aliased
import Error from 'components/Error'
import FlexCenter from 'components/FlexCenter'

export default class ErrorBoundary extends PureComponent {
  static getDerivedStateFromError(error) {
    console.error(error)
    return { error }
  }

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  }

  static defaultProps = {
    children: null,
    className: null,
  }

  state = { error: null }

  render() {
    const { error } = this.state

    if (!error) return this.props.children

    return (
      <FlexCenter className={this.props.className}>
        <Error error={error} />
      </FlexCenter>
    )
  }
}
