// modules
import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { TranslatableString as TS } from '@nike/i18n-react'
import { Table, TableCell, TableHeading, Text } from '@nike/eds'
import { useHistory, useLocation } from 'react-router-dom'
import { parse, serialize } from '@vl/js-lib/browser/url/querystring'
import cx from 'classnames'
import {
  __,
  always,
  applyTo,
  identity,
  lt,
  map,
  multiply,
  path,
  pathOr,
  pipe,
  prop,
  propOr,
  subtract,
  unless,
  when,
} from 'ramda'

// lib
import Pagination from 'components/Pagination'
import Progress from 'components/Progress'
import {
  GET_USER_UPLOADS,
  SEARCH_ATHLETES_QUERY,
  VIEW_BUYLIST,
} from 'lib/graphql/query'

// local
import styles from './ListAthletes.module.scss'
import { dateStringToUSFormat, fixedDateObjectToDateString } from 'utils/date'
import { INTEREST_OPTIONS } from 'views/OnboardingView/InterestsView'

const INTEREST_MAP = INTEREST_OPTIONS.reduce(
  (obj, item) =>
    Object.assign(obj, {
      [item.value]: item,
    }),
  {},
)

export default function ListAthletes() {
  const location = useLocation()
  const history = useHistory()

  // TODO: Replace contact with getUser, might need to get email then get id in databricks..
  // const { data, loading } = useQuery(GET_USER_UPLOADS, {
  //   errorPolicy: 'all',
  //   variables: {
  //     contact: 'zsuare'
  //   },
  // })
  // Should return list of objects with {title, cadence, end_date}

  const test_obj = [
    {
      title: 'Test 1',
      cadence: '* * * * *',
      end_date: '2024-06-30',
    },
    {
      title: 'Test 2',
      cadence: '* * * * *',
      end_date: '2024-06-30',
    },
    {
      title: 'Test 3',
      cadence: '* * * * *',
      end_date: '2024-06-30',
    },
  ]

  // if (loading) return <Progress />

  const TestButton = () => {
    const testApi = async () => {
      const url =
        'https://nike-sole-react.cloud.databricks.com/api/2.0/sql/statements/'
      const body = {
        warehouse_id: '539cd07f276d52db',
        statement: 'SELECT * FROM range(100)',
        wait_timeout: '0s',
      }

      try {
        // TODO: fix this
        const DATABRICKS_TOKEN = 'dapie9de3aafa4f39bae62c3c76e089dee41'
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${DATABRICKS_TOKEN}`,
          },
          body: JSON.stringify(body),
        })

        if (!response.ok) {
          throw new Error('HTTP status ' + response.status)
        }

        const data = await response.json()
        console.log(data)
      } catch (error) {
        console.error('Error:', error)
      }
    }

    return <button onClick={testApi}>Test API</button>
  }

  return (
    <div>
      {test_obj.length > 0 ? (
        test_obj.map((obj, index) => (
          <div key={index}>
            <pre>{JSON.stringify(obj, null, 2)}</pre>
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
      <TestButton />
    </div>
  )
}
