// modules
import React, { memo, useCallback, useContext } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { applyTo, pipe } from 'ramda'
import { Text } from '@nike/eds'
import { CaretCircleLeft } from '@nike/nike-design-system-icons'
import { TranslatableString as TS } from '@nike/i18n-react'
import { Link, NavLink } from 'react-router-dom'
// aliased
import { propTypes } from 'lib/react'
import { ReactComponent as Logo } from 'icons/logo.svg'
import { ReactComponent as AthletesIcon } from 'icons/medal.svg'
import { useLocation } from 'react-router-dom'
import { ReactComponent as AnalyticsIcon } from 'icons/insights.svg'

// local
import styles from './SideNav.module.scss'

export default applyTo(
  ({ className, routes, ...rest }) => {
    const location = useLocation()
    return (
      <div className={cx(styles.container, 'eds--dark', className)} {...rest}>
        <header>
          <div className={styles.logo}>
            <Link to="/admin/home">
              <Logo />
            </Link>
          </div>
          <div className={styles.appName}>
            <Link to="/admin/home">
              The SPD Project
              {/*<AthletesIcon />*/}
              {/*<Text as="h2" font="title-5">*/}
              {/*  <TS*/}
              {/*    stringKey="admin-app-name"*/}
              {/*    primaryValue="SP<em>D</em>"*/}
              {/*    description="Heading in the admin side navigation for the SPD app"*/}
              {/*  />*/}
              {/*</Text>*/}
            </Link>
          </div>
        </header>

        <ul>
          {routes.map(({ path, label, icon }) => (
            <li key={path}>
              <NavLink
                to={`/admin/${path}`}
                className={styles.item}
                activeClassName={styles.active}
                // onClick={itemOnClick || <span className="onClick"/>}
              >
                {icon || <span className="icon" />}
                {label}
              </NavLink>
            </li>
          ))}
          <li>
            <NavLink
              to={location}
              className={styles.item}
              icon={<AnalyticsIcon />}
              onClick={(event) => {
                event.preventDefault()
                window.open(
                  'https://nike-sole-react.cloud.databricks.com/dashboardsv3/01eee0d9b3b11212b4cbc55fe236e318/published?o=554179923494093',
                  '_blank',
                )
              }}
            >
              <AnalyticsIcon />
              Geo Dashboard
            </NavLink>
          </li>
        </ul>
        <div className={styles.bottom}>
          <ul>
            <li>
              <Link to="/logout" className={styles.item}>
                <CaretCircleLeft />
                <TS
                  stringKey="admin-nav-logout-link"
                  primaryValue="Logout"
                  description="Logout link in the admin side navigation"
                />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    )
  },
  pipe(
    propTypes({
      className: PropTypes.string,
      routes: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.node,
          label: PropTypes.node.isRequired,
          path: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
    memo,
  ),
)
