// modules
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { applyTo, pipe } from 'ramda'
import cx from 'classnames'
import { ButtonStyled } from '@nike/nike-design-system-components'

// aliased
import { defaultProps, propTypes } from 'lib/react'

// local
import styles from './PillButton.module.scss'

const PillButton = ({ children, className, selected, ...extra }) => {
  return (
    <ButtonStyled
      appearance="secondary"
      type="button"
      className={cx(className, styles.button, { [styles.selected]: selected })}
      {...extra}
    >
      {children}
    </ButtonStyled>
  )
}

export default applyTo(
  PillButton,
  pipe(
    propTypes({
      children: PropTypes.node.isRequired,
      className: PropTypes.string,
      selected: PropTypes.bool,
    }),
    defaultProps({
      selected: false,
    }),
    memo,
  ),
)
