// modules
import React from 'react'
import {
  applyTo,
  identity,
  map,
  path,
  pathOr,
  pipe,
  prop,
  propOr,
  when,
} from 'ramda'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { Text } from '@nike/eds'
import { DateTimeFormat, TranslatableString as TS } from '@nike/i18n-react'

// lib
import Progress from 'components/Progress'
import {
  ADMIN_GET_ATHLETE_QUERY,
  GET_ADMIN_SURVEY_ANSWERS_FOR_ATHLETE,
  PARALYMPIC_SPORT_LIST_QUERY,
  SPORT_LIST_QUERY,
} from 'lib/graphql/query'
import { formatEnum } from 'lib/filters'
import { propTypes } from 'lib/react'

// local
import styles from './AthleteProfileView.module.scss'
import { Avatar } from '@nike/nike-design-system-components'
import { ReactComponent as TikTokIcon } from 'icons/social/tiktok-color.svg'
import { ReactComponent as DiscordIcon } from 'icons/social/discord-color.svg'
import { ReactComponent as InstagramIcon } from 'icons/social/instagram-color.svg'
import { ReactComponent as FacebookIcon } from 'icons/social/facebook-color.svg'
import { ReactComponent as TwitterIcon } from 'icons/social/twitter-color.svg'
import { ReactComponent as WebIcon } from 'icons/social/web.svg'

import { ReactComponent as PerformanceScienceIcon } from 'icons/interests/Performance science.svg'
import { ReactComponent as RecoveryIcon } from 'icons/interests/Recovery.svg'
import { ReactComponent as SportsResearchIcon } from 'icons/interests/Sports Research.svg'
import { ReactComponent as ProductInnovationIcon } from 'icons/interests/Product Innovation.svg'
import { ReactComponent as MentalTrainingIcon } from 'icons/interests/Mental Training for Performance.svg'
import { ReactComponent as NutritionIcon } from 'icons/interests/Nutrition.svg'
import { ReactComponent as MentalHealthIcon } from 'icons/interests/Mental Health.svg'
import { ReactComponent as SocialJusticeIcon } from 'icons/interests/Social Justice.svg'
import { ReactComponent as MusicIcon } from 'icons/interests/Music.svg'
import { ReactComponent as PhotographyIcon } from 'icons/interests/Photography.svg'
import { ReactComponent as BooksIcon } from 'icons/interests/Books.svg'
import { ReactComponent as ArtIcon } from 'icons/interests/Art.svg'
import { ReactComponent as FoodIcon } from 'icons/interests/Food.svg'
import { ReactComponent as FashionIcon } from 'icons/interests/Fashion.svg'
import { ReactComponent as TravelIcon } from 'icons/interests/Travel.svg'
import { ReactComponent as FamilyIcon } from 'icons/interests/Family.svg'
import { ReactComponent as GamingIcon } from 'icons/interests/Gaming.svg'
import { ReactComponent as ParenthoodIcon } from 'icons/interests/Parenthood.svg'
import { ReactComponent as KidsInSportsIcon } from 'icons/interests/Kids in Sports.svg'
import { ReactComponent as PersonalBrandingIcon } from 'icons/interests/Personal Branding.svg'
import { ReactComponent as PublicSpeakingIcon } from 'icons/interests/Public Speaking.svg'
import { ReactComponent as ContentCreationIcon } from 'icons/interests/Content Creation.svg'
import { ReactComponent as PodcastIcon } from 'icons/interests/Podcast.svg'
import { ReactComponent as AuthorIcon } from 'icons/interests/Author.svg'
import { ReactComponent as YouTubeIcon } from 'icons/interests/YouTube.svg'
import { ReactComponent as MediaTrainingIcon } from 'icons/interests/Media Training.svg'
import { ReactComponent as AdvisingIcon } from 'icons/interests/Advising.svg'
import { ReactComponent as InvestingIcon } from 'icons/interests/Investing.svg'
import { ReactComponent as BusinessCreationIcon } from 'icons/interests/Business Creation.svg'
import { ReactComponent as AthleteCommerceIcon } from 'icons/interests/Athlete Commerce.svg'
import { ReactComponent as CreatorCommerceIcon } from 'icons/interests/Creator Commerce.svg'
import { ReactComponent as EntrepreneurshipIcon } from 'icons/interests/Entrepreneurship.svg'
import { ReactComponent as LeadershipIcon } from 'icons/interests/Leadership.svg'
import { ReactComponent as TeamBuildingIcon } from 'icons/interests/Team Building.svg'
import { ReactComponent as CoachingIcon } from 'icons/interests/Coaching.svg'
import { ReactComponent as MentoringIcon } from 'icons/interests/Mentoring.svg'
import { ReactComponent as LGBTQIAPlusIcon } from 'icons/interests/LGBTQIA+.svg'
import { ReactComponent as DiversityIcon } from 'icons/interests/Diversity Equity and Inclusion.svg'
import { ReactComponent as SustainabilityIcon } from 'icons/interests/Sustainability.svg'
import { ReactComponent as NonprofitsIcon } from 'icons/interests/Non-profits.svg'
import { ReactComponent as CommunityBuildingIcon } from 'icons/interests/Community Building.svg'
import { ReactComponent as CommunityGrowthIcon } from 'icons/interests/Community Growth.svg'
import { ReactComponent as ArrowForward } from 'icons/general/arrow_forward.svg'
import PillButton from 'components/PillButton'
import { useHistory } from 'react-router-dom'

const DEFAULT_URL = 'https://picsum.photos/400'

const OPTIONS = [
  // Athletes as Community
  {
    value: 'LGBTQIA+',
    label: (
      <TS
        stringKey="interest-opts-lgbtqiaPlus"
        primaryValue="LGBTQIA+"
        description="Interest input option value for LGBTQIA+"
      />
    ),
    icon: <LGBTQIAPlusIcon />,
  },
  {
    value: 'Diversity, Equity, and Inclusion',
    label: (
      <TS
        stringKey="interest-opts-diversityEquityInclusion"
        primaryValue="Diversity, Equity, and Inclusion"
        description="Interest input option value for Diversity, Equity, and Inclusion"
      />
    ),
    icon: <DiversityIcon />,
  },
  {
    value: 'Sustainability',
    label: (
      <TS
        stringKey="interest-opts-sustainability"
        primaryValue="Sustainability"
        description="Interest input option value for Sustainability"
      />
    ),
    icon: <SustainabilityIcon />,
  },
  {
    value: 'Non-profits',
    label: (
      <TS
        stringKey="interest-opts-nonprofits"
        primaryValue="Non-profits"
        description="Interest input option value for Non-profits"
      />
    ),
    icon: <NonprofitsIcon />,
  },
  {
    value: 'Community Building',
    label: (
      <TS
        stringKey="interest-opts-communityBuilding"
        primaryValue="Community Building"
        description="Interest input option value for Community Building"
      />
    ),
    icon: <CommunityBuildingIcon />,
  },
  {
    value: 'Community Growth',
    label: (
      <TS
        stringKey="interest-opts-communityGrowth"
        primaryValue="Community Growth"
        description="Interest input option value for Community Growth"
      />
    ),
    icon: <CommunityGrowthIcon />,
  },
  // Athletes as Athletes
  {
    value: 'Performance Science',
    label: (
      <TS
        primaryValue="Performance Science"
        stringKey="interest-opts-performanceScience"
        description="Interest input option value for Performance Science"
      />
    ),
    icon: <PerformanceScienceIcon />,
  },
  {
    value: 'Recovery',
    label: (
      <TS
        stringKey="interest-opts-recovery"
        primaryValue="Recovery"
        description="Interest input option value for Recovery"
      />
    ),
    icon: <RecoveryIcon />,
  },
  {
    value: 'Sports Research',
    label: (
      <TS
        stringKey="interest-opts-sportsResearch"
        primaryValue="Sports Research"
        description="Interest input option value for Sports Research"
      />
    ),
    icon: <SportsResearchIcon />,
  },
  {
    value: 'Product Innovation',
    label: (
      <TS
        stringKey="interest-opts-productInnovation"
        primaryValue="Product Innovation"
        description="Interest input option value for Product Innovation"
      />
    ),
    icon: <ProductInnovationIcon />,
  },
  {
    value: 'Mental Training for Performance',
    label: (
      <TS
        stringKey="interest-opts-mentalTraining"
        primaryValue="Mental Training for Performance"
        description="Interest input option value for Mental Training for Performance"
      />
    ),
    icon: <MentalTrainingIcon />,
  },
  {
    value: 'Nutrition',
    label: (
      <TS
        stringKey="interest-opts-nutrition"
        primaryValue="Nutrition"
        description="Interest input option value for Nutrition"
      />
    ),
    icon: <NutritionIcon />,
  },
  // Athletes as Humans
  {
    value: 'Mental Health',
    label: (
      <TS
        stringKey="interest-list-mental-health"
        primaryValue="Mental Health"
        description="Interest input option value for Mental Health"
      />
    ),
    icon: <MentalHealthIcon />,
  },
  {
    value: 'Social Justice',
    label: (
      <TS
        stringKey="interest-opts-socialJustice"
        primaryValue="Social Justice"
        description="Interest input option value for Social Justice"
      />
    ),
    icon: <SocialJusticeIcon />,
  },
  {
    value: 'Music',
    label: (
      <TS
        stringKey="interest-opts-music"
        primaryValue="Music"
        description="Interest input option value for Music"
      />
    ),
    icon: <MusicIcon />,
  },
  {
    value: 'Photography',
    label: (
      <TS
        stringKey="interest-opts-photography"
        primaryValue="Photography"
        description="Interest input option value for Photography"
      />
    ),
    icon: <PhotographyIcon />,
  },
  {
    value: 'Books',
    label: (
      <TS
        stringKey="interest-opts-books"
        primaryValue="Books"
        description="Interest input option value for Books"
      />
    ),
    icon: <BooksIcon />,
  },
  {
    value: 'Art',
    label: (
      <TS
        stringKey="interest-opts-art"
        primaryValue="Art"
        description="Interest input option value for Art"
      />
    ),
    icon: <ArtIcon />,
  },
  {
    value: 'Food',
    label: (
      <TS
        stringKey="interest-opts-food"
        primaryValue="Food"
        description="Interest input option value for Food"
      />
    ),
    icon: <FoodIcon />,
  },
  {
    value: 'Fashion',
    label: (
      <TS
        stringKey="interest-opts-fashion"
        primaryValue="Fashion"
        description="Interest input option value for Fashion"
      />
    ),
    icon: <FashionIcon />,
  },
  {
    value: 'Travel',
    label: (
      <TS
        stringKey="interest-opts-travel"
        primaryValue="Travel"
        description="Interest input option value for Travel"
      />
    ),
    icon: <TravelIcon />,
  },
  {
    value: 'Family',
    label: (
      <TS
        stringKey="interest-opts-family"
        primaryValue="Family"
        description="Interest input option value for Family"
      />
    ),
    icon: <FamilyIcon />,
  },
  {
    value: 'Gaming',
    label: (
      <TS
        stringKey="interest-opts-gaming"
        primaryValue="Gaming"
        description="Interest input option value for Gaming"
      />
    ),
    icon: <GamingIcon />,
  },
  {
    value: 'Parenthood',
    label: (
      <TS
        stringKey="interest-opts-parenthood"
        primaryValue="Parenthood"
        description="Interest input option value for Parenthood"
      />
    ),
    icon: <ParenthoodIcon />,
  },
  {
    value: 'Kids in Sports',
    label: (
      <TS
        stringKey="interest-opts-kidsInSports"
        primaryValue="Kids in Sports"
        description="Interest input option value for Kids in Sports"
      />
    ),
    icon: <KidsInSportsIcon />,
  },
  // Athletes as Brands
  {
    value: 'Personal Branding',
    label: (
      <TS
        stringKey="interest-opts-personalBranding"
        primaryValue="Personal Branding"
        description="Interest input option value for Personal Branding"
      />
    ),
    icon: <PersonalBrandingIcon />,
  },
  {
    value: 'Public Speaking',
    label: (
      <TS
        stringKey="interest-opts-publicSpeaking"
        primaryValue="Public Speaking"
        description="Interest input option value for Public Speaking"
      />
    ),
    icon: <PublicSpeakingIcon />,
  },
  {
    value: 'Content Creation',
    label: (
      <TS
        stringKey="interest-opts-contentCreation"
        primaryValue="Content Creation"
        description="Interest input option value for Content Creation"
      />
    ),
    icon: <ContentCreationIcon />,
  },
  {
    value: 'Podcasting',
    label: (
      <TS
        stringKey="interest-opts-podcasting"
        primaryValue="Podcasting"
        description="Interest input option value for Podcasting"
      />
    ),
    icon: <PodcastIcon />,
  },
  {
    value: 'YouTube',
    label: (
      <TS
        stringKey="interest-opts-youtube"
        primaryValue="YouTube"
        description="Interest input option value for YouTube"
      />
    ),
    icon: <YouTubeIcon />,
  },
  {
    value: 'Author',
    label: (
      <TS
        stringKey="interest-opts-author"
        primaryValue="Author"
        description="Interest input option value for Author"
      />
    ),
    icon: <AuthorIcon />,
  },
  {
    value: 'Media Training',
    label: (
      <TS
        stringKey="interest-opts-mediaTraining"
        primaryValue="Media Training"
        description="Interest input option value for Media Training"
      />
    ),
    icon: <MediaTrainingIcon />,
  },
  // Athletes as Businesses
  {
    value: 'Advising',
    label: (
      <TS
        stringKey="interest-opts-advising"
        primaryValue="Advising"
        description="Interest input option value for Advising"
      />
    ),
    icon: <AdvisingIcon />,
  },
  {
    value: 'Investing',
    label: (
      <TS
        stringKey="interest-opts-investing"
        primaryValue="Investing"
        description="Interest input option value for Investing"
      />
    ),
    icon: <InvestingIcon />,
  },
  {
    value: 'Business Creation',
    label: (
      <TS
        stringKey="interest-opts-businessCreation"
        primaryValue="Business Creation"
        description="Interest input option value for Business Creation"
      />
    ),
    icon: <BusinessCreationIcon />,
  },
  {
    value: 'Athlete Commerce',
    label: (
      <TS
        stringKey="interest-opts-athleteCommerce"
        primaryValue="Athlete Commerce"
        description="Interest input option value for Athlete Commerce"
      />
    ),
    icon: <AthleteCommerceIcon />,
  },
  {
    value: 'Creator Commerce',
    label: (
      <TS
        stringKey="interest-opts-creatorCommerce"
        primaryValue="Creator Commerce"
        description="Interest input option value for Creator Commerce"
      />
    ),
    icon: <CreatorCommerceIcon />,
  },
  {
    value: 'Entrepreneurship',
    label: (
      <TS
        stringKey="interest-opts-entrepreneurship"
        primaryValue="Entrepreneurship"
        description="Interest input option value for Entrepreneurship"
      />
    ),
    icon: <EntrepreneurshipIcon />,
  },
  // Athletes as Leaders
  {
    value: 'Leadership',
    label: (
      <TS
        stringKey="interest-opts-leadership"
        primaryValue="Leadership"
        description="Interest input option value for Leadership"
      />
    ),
    icon: <LeadershipIcon />,
  },
  {
    value: 'Team Building',
    label: (
      <TS
        stringKey="interest-opts-teamBuilding"
        primaryValue="Team Building"
        description="Interest input option value for Team Building"
      />
    ),
    icon: <TeamBuildingIcon />,
  },
  {
    value: 'Coaching',
    label: (
      <TS
        stringKey="interest-opts-coaching"
        primaryValue="Coaching"
        description="Interest input option value for Coaching"
      />
    ),
    icon: <CoachingIcon />,
  },
  {
    value: 'Mentoring',
    label: (
      <TS
        stringKey="interest-opts-mentoring"
        primaryValue="Mentoring"
        description="Interest input option value for Mentoring"
      />
    ),
    icon: <MentoringIcon />,
  },
]

const formatBirthday = pipe(
  prop('birthday'),
  when(
    identity,
    pipe(
      ({ year, month, day }) => new Date(year, month - 1, day), // JS month is 0 indexed
      (date) => (
        <DateTimeFormat date={date} year="numeric" month="long" day="numeric" />
      ),
    ),
  ),
)
const calcWillingToWorkWithKids = (athlete) => {
  switch (athlete.workWithKids) {
    case 0:
      return 'No'
    case 1:
      return '20%'
    case 2:
      return '40%'
    case 3:
      return '60%'
    case 4:
      return '80%'
    case 5:
      return '100%'
    default:
      return 'N/A'
  }
}

const calcWillingToEngageInFamilyPhotoOps = (athlete) => {
  switch (athlete.familyInMedia) {
    case 0:
      return 'No'
    case 1:
      return '20%'
    case 2:
      return '40%'
    case 3:
      return '60%'
    case 4:
      return '80%'
    case 5:
      return '100%'
    default:
      return 'N/A'
  }
}

const transformTierLabel = (tier) => {
  switch (tier) {
    case 'ICON':
      return 'Icon Athlete'
    case 'DEFINING':
      return 'Defining Athlete'
    case 'LEAD':
      return 'Lead Athlete'
    case 'CORE':
      return 'Core Athlete'
    case 'RISING':
      return 'Rising Athlete'
    default:
      break
  }
}

const convertDate = (utcDate) => {
  const date = new Date(utcDate)
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
}

const formatSocialHandles = pipe(
  propOr([], 'socialHandles'),
  when(identity, (handles) => (
    <div>
      {map(
        ({ type, handle }) => (
          <a
            key={`${type}-${handle}`}
            href={
              (type === 'TIKTOK'
                ? `https://tiktok.com/`
                : type === 'DISCORD'
                ? `https://discord.com/`
                : type === 'INSTAGRAM'
                ? `https://instagram.com/`
                : type === 'TWITTER'
                ? `https://twitter.com/`
                : type === 'FACEBOOK'
                ? `https://facebook.com//`
                : '') + handle
            }
            target="_blank"
            rel="noreferrer"
          >
            {type === 'TIKTOK' ? (
              <TikTokIcon alt={'tiktok account'} />
            ) : type === 'DISCORD' ? (
              <DiscordIcon alt={'discord account'} />
            ) : type === 'INSTAGRAM' ? (
              <InstagramIcon alt={'instagram account'} />
            ) : type === 'TWITTER' ? (
              <TwitterIcon alt={'twitter account'} />
            ) : type === 'FACEBOOK' ? (
              <FacebookIcon alt={'facebook account'} />
            ) : (
              <WebIcon alt={'website'} />
            )}
          </a>
        ),
        handles,
      )}
    </div>
  )),
)

export const sortSportOptions = (a, b) => (a.label > b.label ? 1 : -1)
export const buildSportOptions = ({ label, value }) => ({ label, value })

const AthleteProfileView = (props) => {
  const id = path(['match', 'params', 'id'], props)
  const history = useHistory()

  const { data, loading } = useQuery(ADMIN_GET_ATHLETE_QUERY, {
    variables: {
      id,
    },
    errorPolicy: 'all',
  })

  const athlete = pathOr({}, ['node'], data)

  const { data: surveyData, surveysLoading } = useQuery(
    GET_ADMIN_SURVEY_ANSWERS_FOR_ATHLETE,
    {
      variables: {
        id,
      },
      errorPolicy: 'all',
    },
  )

  const rawAnswers = pathOr(
    null,
    ['node', 'user', 'surveyAnswers', 'edges'],
    surveyData,
  )

  const surveyAnswers = rawAnswers?.filter((obj) => obj !== null)

  const { data: sportData, loading: sportLoading } = useQuery(
    SPORT_LIST_QUERY,
    { errorPolicy: 'all' },
  )
  const { data: paralympicData, loading: paralympicLoading } = useQuery(
    PARALYMPIC_SPORT_LIST_QUERY,
    { errorPolicy: 'all' },
  )

  const sportOptions = sportData?.sports?.values
    ?.map(buildSportOptions)
    .sort(sortSportOptions)

  const paralympicOptions = paralympicData?.paralympics?.values
    ?.map(buildSportOptions)
    .sort(sortSportOptions)

  const getInterestPills = (athlete) => {
    return athlete.interests.map((interest) => {
      const definedOption = OPTIONS.find((opt) => opt.value === interest)
      return (
        <>
          {definedOption ? (
            <PillButton
              key={definedOption.value}
              data-value={definedOption.value}
              background="dark"
              iconPlacement="start"
              Icon={definedOption.icon}
            >
              {definedOption.label}
            </PillButton>
          ) : (
            <PillButton key={interest} data-value={interest} background="dark">
              {interest}
            </PillButton>
          )}
        </>
      )
    })
  }

  if (loading || surveysLoading || sportLoading || paralympicLoading) {
    return <Progress />
  }

  return (
    <div className={styles.container}>
      <div className={styles.navContainer}>
        <div className={styles.backContainer}>
          <div className={styles.nav}>
            <span className={styles.backButton} onClick={history.goBack}>
              <ArrowForward />
            </span>
          </div>
        </div>
        <div className={styles.topRightActionButtonsContainer}>
          {/* TODO: PUT EDIT AND EXPORT BUTTONS HERE */}
        </div>
      </div>
      <div className={styles.topContainer}>
        <div className={styles.nameInfoWrapper}>
          <div className={styles.nameInfoInnerWrapper}>
            <Avatar
              image={{
                src: pathOr(DEFAULT_URL, ['user', 'avatar', 'url'], athlete),
                alt: 'avatar',
              }}
              size="173px"
              classes={{
                Root: styles.athleteAvatar,
              }}
            />
            <div className={styles.nameAndUpdateByWrapper}>
              <Text font="title-1" className={styles.name}>
                {prop('firstName', athlete)} {prop('middleName', athlete)}{' '}
                {prop('lastName', athlete)} {prop('nameSuffix', athlete)}
              </Text>
              {athlete.lastUpdated ? (
                <span>
                  Last updated by: {athlete.lastUpdated?.by?.__typename}, on{' '}
                  {convertDate(athlete.lastUpdated?.date)}
                </span>
              ) : (
                'Athlete has not yet logged into Athlete HQ'
              )}
            </div>
            {athlete.tier && (
              <Text font="body-3" className={styles.tier}>
                <span className={styles.tierChip}>
                  {transformTierLabel(prop('tier', athlete))}
                </span>
              </Text>
            )}
          </div>
        </div>
        <div className={styles.adminProfileCard}>
          <div className={styles.topLeft}>
            <div className={styles.socialSection}>
              {formatSocialHandles(athlete)}
            </div>
            <div className={styles.basicData}>
              <div className={styles.doubleRow}>
                <div className={styles.basicDataReadOnly}>
                  <div className={styles.label}>First and middle name</div>
                  <div className={styles.data}>
                    {athlete.firstName} {athlete.middleName}
                  </div>
                </div>
                <div className={styles.basicDataReadOnly}>
                  <div className={styles.label}>Last name</div>
                  <div className={styles.data}>
                    {athlete.lastName} {athlete.suffix}
                  </div>
                </div>
              </div>
              <div className={styles.doubleRow}>
                <div className={styles.basicDataReadOnly}>
                  <div className={styles.label}>Nickname</div>
                  <div className={styles.data}>{athlete.preferredFullName}</div>
                </div>
              </div>
              <div className={styles.doubleRow}>
                <div className={styles.basicDataReadOnly}>
                  <div className={styles.label}>Sport</div>
                  <div className={styles.data}>
                    {
                      sportOptions?.find((o) => o.value === athlete.sport)
                        ?.label
                    }
                  </div>
                </div>
                <div className={styles.basicDataReadOnly}>
                  <div className={styles.label}>Discipline</div>
                  <div className={styles.data}>
                    {
                      paralympicOptions?.find(
                        (o) => o.value === athlete.paralympicSport,
                      )?.label
                    }
                  </div>
                </div>
              </div>
              <hr />
              <div className={styles.doubleRow}>
                <div className={styles.basicDataReadOnly}>
                  <div className={styles.label}>Birthday</div>
                  <div className={styles.data}>{formatBirthday(athlete)}</div>
                </div>
                <div className={styles.basicDataReadOnly}>
                  <div className={styles.label}>Age</div>
                  <div className={styles.data}>{athlete.age}</div>
                </div>
              </div>
              <hr />
              <div className={styles.doubleRow}>
                <div className={styles.basicDataReadOnly}>
                  <div className={styles.label}>Current residence</div>
                  <div className={styles.data}>{athlete.currentResidence}</div>
                </div>
                <div className={styles.basicDataReadOnly}>
                  <div className={styles.label}>Hometown</div>
                  <div className={styles.data}>{athlete.hometown}</div>
                </div>
              </div>
              <hr />
              <div className={styles.doubleRow}>
                <div className={styles.basicDataReadOnly}>
                  <div className={styles.label}>Email</div>
                  <div className={styles.data}>{athlete.email}</div>
                </div>
                <div className={styles.basicDataReadOnly}>
                  <div className={styles.label}>Gender</div>
                  <div className={styles.data}>
                    {formatEnum(athlete.gender)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.adminProfileCard}>
          <div className={styles.cardContent}>
            <div className={styles.subHeading}>Sport as Play</div>
            <div className={styles.basicDataReadOnly}>
              <div className={styles.label}>Willing to work with kids</div>
              <div className={styles.data}>
                {calcWillingToWorkWithKids(athlete)}
              </div>
            </div>
            <div className={styles.basicDataReadOnly}>
              <div className={styles.label}>
                Willing to engage in family photo opportunities
              </div>
              <div className={styles.data}>
                {calcWillingToEngageInFamilyPhotoOps(athlete)}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.adminProfileCard}>
          <div className={styles.cardContent}>
            <div className={styles.equipmentSubHeading}>
              Interests{' '}
              <span className={styles.chip}>{athlete.interests?.length}</span>
            </div>
            <div className={styles.interestsSection}>
              {getInterestPills(athlete)}
            </div>
          </div>
        </div>
        <div className={styles.adminProfileCard}>
          <div className={styles.cardContent}>
            <div className={styles.equipmentSubHeading}>Equipment</div>
            <div className={styles.innerSubHeading}>Competition</div>
            <div className={styles.doubleRow}>
              <div className={styles.basicDataReadOnly}>
                <div className={styles.label}>Top size</div>
                <div className={styles.data}>
                  {athlete.measurements?.competition?.topSize?.gender}{' '}
                  {athlete.measurements?.competition?.topSize?.size}
                </div>
              </div>
              <div className={styles.basicDataReadOnly}>
                <div className={styles.label}>Bottom size</div>
                <div className={styles.data}>
                  {athlete.measurements?.competition?.bottomSize?.gender}{' '}
                  {athlete.measurements?.competition?.bottomSize?.size}
                </div>
              </div>
            </div>
            <div className={styles.doubleRow}>
              <div className={styles.basicDataReadOnly}>
                <div className={styles.label}>Shoe size</div>
                <div className={styles.data}>
                  {athlete.measurements?.competition?.shoeSize?.gender}{' '}
                  {athlete.measurements?.competition?.shoeSize?.size}
                </div>
              </div>
            </div>
            <hr />
            <div className={styles.innerSubHeading}>Training</div>
            <div className={styles.doubleRow}>
              <div className={styles.basicDataReadOnly}>
                <div className={styles.label}>Top size</div>
                <div className={styles.data}>
                  {athlete.measurements?.training?.topSize?.gender}{' '}
                  {athlete.measurements?.training?.topSize?.size}
                </div>
              </div>
              <div className={styles.basicDataReadOnly}>
                <div className={styles.label}>Bottom size</div>
                <div className={styles.data}>
                  {athlete.measurements?.training?.bottomSize?.gender}{' '}
                  {athlete.measurements?.training?.bottomSize?.size}
                </div>
              </div>
            </div>
            <div className={styles.doubleRow}>
              <div className={styles.basicDataReadOnly}>
                <div className={styles.label}>Shoe size</div>
                <div className={styles.data}>
                  {athlete.measurements?.training?.shoeSize?.gender}{' '}
                  {athlete.measurements?.training?.shoeSize?.size}
                </div>
              </div>
            </div>
            <hr />
            <div className={styles.innerSubHeading}>Lifestyle</div>
            <div className={styles.doubleRow}>
              <div className={styles.basicDataReadOnly}>
                <div className={styles.label}>Top size</div>
                <div className={styles.data}>
                  {athlete.measurements?.lifestyle?.topSize?.gender}{' '}
                  {athlete.measurements?.lifestyle?.topSize?.size}
                </div>
              </div>
              <div className={styles.basicDataReadOnly}>
                <div className={styles.label}>Bottom size</div>
                <div className={styles.data}>
                  {athlete.measurements?.lifestyle?.bottomSize?.gender}{' '}
                  {athlete.measurements?.lifestyle?.bottomSize?.size}
                </div>
              </div>
            </div>
            <div className={styles.doubleRow}>
              <div className={styles.basicDataReadOnly}>
                <div className={styles.label}>Shoe size</div>
                <div className={styles.data}>
                  {athlete.measurements?.lifestyle?.shoeSize?.gender}{' '}
                  {athlete.measurements?.lifestyle?.shoeSize?.size}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className={styles.bottomContainer}>
        {!surveysLoading &&
          surveyAnswers?.map((answer) => {
            return (
              <div
                className={styles.adminProfileCard}
                key={answer.node.question.propmpt}
              >
                <div className={styles.cardContent}>
                  <div className={styles.surveyQuestion}>
                    {answer.node.question.prompt}
                  </div>
                  <div className={styles.surveyAnswer}>
                    {answer.node.choice.value}
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default applyTo(
  AthleteProfileView,
  pipe(
    propTypes({
      match: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
    React.memo,
  ),
)
