// modules
import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import {
  applyTo,
  assoc,
  identity,
  isEmpty,
  lensProp,
  map,
  over,
  path,
  pathOr,
  pipe,
  prop,
  propOr,
  sortBy,
  when,
} from 'ramda'
import { TranslatableString as TS } from '@nike/i18n-react'
import {
  ButtonGroup,
  Button,
  Checkbox,
  CheckboxGroup,
  Link,
  SidePanel,
  Spinner,
} from '@nike/eds'

// aliased
import { isChecked, toggleValue } from 'lib/filters'
import { ADMIN_AGES_FILTER_QUERY } from 'lib/graphql/query'
import { propTypes, defaultProps } from 'lib/react'

// local
import styles from '../FilterList.module.scss'

const isClearDisabled = pipe(propOr([], 'age'), isEmpty)

const AgeFilter = ({ isOpen, label, onChange, onDismiss, value }) => {
  const handleChange = useCallback(
    (evt) => {
      const val = path(['target', 'id'], evt)
      onChange(toggleValue('age', val, value))
    },
    [onChange, value],
  )

  const { data, loading } = useQuery(ADMIN_AGES_FILTER_QUERY, {
    errorPolicy: 'all',
  })
  const options = pipe(
    pathOr({}, ['searchAthletes', 'filterAggregations']),
    over(lensProp('age'), when(identity, sortBy(prop('key')))),
  )(data)

  const handleSelectAll = useCallback(() => {
    const allOptions = pipe(propOr([], 'age'), map(prop('key')))(options)
    onChange(assoc('age', allOptions, value))
  }, [onChange, options, value])

  const handleClear = useCallback(() => {
    onChange(assoc('age', [], value))
  }, [onChange, value])

  return (
    <SidePanel
      hasScrim={true}
      headerSlot={label}
      footerSlot={
        <ButtonGroup className={styles.actions}>
          <Button
            variant="secondary"
            onClick={handleClear}
            disabled={isClearDisabled(value)}
          >
            <TS
              stringKey="admin-filter-clear-button"
              primaryValue="Clear"
              description="Label for the button to clear or reset the current filter on the admin athlete search page"
            />
          </Button>
        </ButtonGroup>
      }
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <div>
        <Link
          className={styles.selectAll}
          variant="secondary"
          as="button"
          onClick={handleSelectAll}
          disabled={loading}
        >
          <TS
            stringKey="admin-filter-select-all-label"
            primaryValue="Select All"
            description="Label for button to select all filter options on the admin athlete search page"
          />
        </Link>
        {loading ? (
          <Spinner />
        ) : (
          <CheckboxGroup
            id="age-filter"
            label={label}
            hideLabel={true}
            name="age"
            onChange={handleChange}
          >
            {pipe(
              propOr([], 'age'),
              map(({ key: term }) => (
                <Checkbox
                  label={term}
                  value={term}
                  id={term}
                  checked={isChecked('age', term, value)}
                />
              )),
            )(options)}
          </CheckboxGroup>
        )}
      </div>
    </SidePanel>
  )
}

export default applyTo(
  AgeFilter,
  pipe(
    propTypes({
      isOpen: PropTypes.bool,
      label: PropTypes.node,
      onChange: PropTypes.func.isRequired,
      onDismiss: PropTypes.func,
      value: PropTypes.shape({}),
    }),
    defaultProps({
      isOpen: false,
    }),
    memo,
  ),
)
