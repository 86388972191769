import styles from './ResponsesCard.module.scss'
import PollChoice from 'components/Poll/PollChoice'
import { useHistory, useLocation } from 'react-router-dom'
import { Fragment } from 'react'

export default function ResponsesCard({
  handle,
  totalResponses,
  totalViews,
  prompt,
  choices,
  collapsed,
}) {
  const location = useLocation()
  const history = useHistory()
  return (
    <div
      className={
        collapsed ? styles.CollapsedResponsesCard : styles.ResponsesCard
      }
      onClick={() => {
        history.push(`${location.pathname}/${handle}`)
      }}
    >
      <h1 className={styles.prompt}>{prompt}</h1>

      {!collapsed && (
        <Fragment>
          <div className={styles.totals}>
            {totalResponses && (
              <div className={styles.total}>
                {`${totalResponses} ${
                  totalResponses === 1 ? 'response' : 'responses'
                }`}
              </div>
            )}

            {totalViews && (
              <div className={styles.total}>
                {`${totalViews} ${totalViews === 1 ? 'view' : 'views'}`}
              </div>
            )}
          </div>

          <div className={styles.choices}>
            {choices.map((choice) => (
              <PollChoice key={choice.value} choice={choice} />
            ))}
          </div>
        </Fragment>
      )}
    </div>
  )
}
