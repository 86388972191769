import axios from 'axios'

const { Kafka } = require('kafkajs')
const request = require('request')

// TODO: Update in SSM
const BROKER_URL_HTTP =
  '19c62c3a-701a-448d-b364-f0b1c83c861b.ingest.na.nrtd-staging.platforms.nike.com:9500' // http
const BROKER_URL =
  '19c62c3a-701a-448d-b364-f0b1c83c861b.ingest.na.nrtd-staging.platforms.nike.com:9500'
const TOPIC =
  'test-us-west-2-general-v2-19c62c3a-701a-448d-b364-f0b1c83c861b-gsma-spd-web'
const OAUTH_TOKEN_URL =
  'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/token'
const OAUTH_CLIENT_ID = 'nike.valiantlabs.athp-web'
const OAUTH_CLIENT_SECRET =
  'wecmMJ3AZYs-PSXVaUDD6aNeShYDunMVOtYbp0BTWAR_KLpp9CAprGlQI_LcYyTo'
// For future schema validation
const SCHEMA_REGISTRY_URL = 'https://schema.nrtd-staging.platforms.nike.com'
const SCHEMA_ID = '<returned after creating a schema for your NSP 3 Stream>'

async function postRequest(spdToken, message_value) {
  const url =
    'https://68644030-a5bc-499d-a4f9-7e5ea7817416.ingest.na.nrtd-staging.platforms.nike.com/rest'
  const token = spdToken
  const data = message_value
  // 'application/vnd.kafka.json.v2+json'
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
  }
  console.log('start postreq')
  const response = await axios.post(url, data, config)
  console.log(response.data)
  console.log('end postreq')

  // try {
  //     const response = await axios.post(url, data, config);
  //     console.log(response.data);
  //     console.log("end postreq")
  // } catch (error) {
  //     console.error('Error making POST request:', error);
  // }
}

export const produceMessage = async (spdToken) => {
  console.log('start produceMessage')

  const record = {
    athleteId: 'test',
    // userId: 'test',
    anonymousId: 'test',
    messageId: 'test',

    original_timestamp: 'test',
    timestamp: 'test',
    receivedAt: 'test',
    sentAt: 'test',

    // event,
    type: 'test',

    // userId,
    // properties,

    // properties: {
    //     app: {
    //         domain: 'string',
    //         build: 'string',
    //         version: 'string'
    //     },
    //     view: {
    //         experienceType: 'string',
    //         pageType: 'string',
    //         pageDetail: 'string',
    //         pageName: 'string'
    //     },
    //     locale: {
    //         country: 'string',
    //         language: 'string'
    //     },
    //     consumer: {
    //         oktaId: 'string',
    //         upmId: 'string'
    //     }
    // },
    context: {
      library: {
        name: 'string',
        version: 'string',
      },
      page: {
        title: 'string',
        path: 'string',
        url: 'string',
        referer: 'string',
        search: 'string',
      },
      userAgentData: {
        brands: 'string',
        platform: 'string',
        mobile: 'string',
      },
      userAgent: 'string',
      ip: 'string',
    },
  }

  const jsonString = JSON.stringify(record)
  const message_value = Buffer.from(jsonString, 'utf8')
  console.log('start awaiting postRequest')
  await postRequest(spdToken, record)
  console.log('done awaiting postRequest')
  // console.log(message_value);
  // process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
  // const formData = querystring.stringify({
  //     grant_type: 'client_credentials',
  //     client_id: OAUTH_CLIENT_ID,
  //     client_secret: OAUTH_CLIENT_SECRET
  // });
  // try {
  //     console.log("start awaiting postRequest")
  //     await postRequest(spdToken, record);
  //     console.log("done awaiting postRequest")
  // } catch (error) {
  //     console.error('postRequest kafka http:', error);
  // }
}

// main().catch(console.error);
