import { subYears } from 'date-fns'

export const fixedDateObjectToDateString = ({ year, month, day } = {}) => {
  if (!year || !month || !day) return null

  const monthString = String(month).padStart(2, '0')
  const dayString = String(day).padStart(2, '0')
  return `${year}-${monthString}-${dayString}`
}

export const dateStringToFixedDateObject = (dateString) => {
  if (!dateString) return null

  const [year, month, day] = dateString.split('-')

  return {
    year: parseInt(year, 10),
    month: parseInt(month, 10),
    day: parseInt(day, 10),
  }
}

export const dateStringToUSFormat = (dateString) => {
  if (!dateString) return null

  const [year, month, day] = dateString.split('-')

  return `${month}/${day}/${year}`
}

export const dateToDateString = (date) => {
  if (!date) return null

  return date.toISOString().split('T')[0]
}

export const dateStringToDate = (str) => {
  if (!str) return null

  return new Date(str)
}

export const maxDate = subYears(new Date(), 18)
