// modules
import React, { memo, useEffect } from 'react'
import { Switch, useHistory } from 'react-router-dom'
import { matchPath } from 'react-router'
import { isNil, not, pipe, replace, trim } from 'ramda'

// lib
import { useCurrentUser } from 'components/UserProvider'
import { sendPageEvent } from 'utils/event'
import { PageEvents } from 'utils/definitions'

const isProfilePage = pipe(
  (location) =>
    matchPath(location.pathname, {
      path: '/profile',
    }),
  isNil,
  not,
)

const isOnboardingPage = pipe(
  (location) =>
    matchPath(location.pathname, {
      path: '/onboarding',
      exact: true,
    }),
  isNil,
  not,
)

const toPageDetail = pipe(replace('/', ' '), trim)

export default memo(() => {
  console.log('loading ProtectedView')
  // const { signIn, needsSignIn } = useEliteAuth()

  const user = useCurrentUser()
  const history = useHistory()

  useEffect(() => {
    return history.listen((location) => {
      // Ignore profile and onboarding pages - those have special page events
      if (!isProfilePage(location) && !isOnboardingPage(location)) {
        // Send page view on page change
        console.log('Protected View sendPageEvent on change')
        sendPageEvent(
          PageEvents.ContentPageViewed,
          toPageDetail(location.pathname),
          {
            pageType: PageEvents.ContentPageViewed.properties.view.pageType,
            user,
          },
        )
      }
    })
  }, [history, user])

  // useEffect(() => {
  //   if (needsSignIn) {
  //     signIn()
  //   }
  // }, [needsSignIn, signIn])
  //
  // if (needsSignIn) return <Progress />

  return (
    <Switch>
      {/*<Route path="/welcome" exact={true} component={WelcomeView} />*/}
      {/*<Route*/}
      {/*  path="/onboarding"*/}
      {/*  exact={true}*/}
      {/*  component={OnboardingView}*/}
      {/*  user={user}*/}
      {/*/>*/}
      {/*<Route path="/profile/:id?" component={ProfileView} />*/}
      {/*<Route path="/blog" component={BlogView} />*/}
      {/*<Route path="/polls/archive" component={PollArchiveView} />*/}
      {/*<Route component={NotFound} />*/}
    </Switch>
  )
})
