// modules
import React from 'react'
import { TranslatableString as TS } from '@nike/i18n-react'

// local
import styles from './AthletePollHeader.module.scss'

const AthletePollHeader = ({ prompt }) => {
  return (
    <div className={styles.AthletePollHeader}>
      <div className={styles.avatar}></div>
      <div className={styles.name}>
        <TS
          stringKey="poll-header-name-label"
          primaryValue="Name"
          description="Name label for poll header"
        />
      </div>
      <div className={styles.prompt}>{prompt}</div>
      <div className={styles.date}>
        <TS
          stringKey="poll-header-date-label"
          primaryValue="Date"
          description="Date label for poll header"
        />
      </div>
    </div>
  )
}

export default AthletePollHeader
