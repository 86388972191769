// modules
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { NikeDesignSystemProvider } from '@nike/nike-design-system-components'
import { NikeI18nProvider } from '@nike/i18n-react'
import '@nike/eds/dist/index.css'
import '@nike/nike-design-system-components/polyfills'

// lib
import ApolloProvider from 'components/ApolloProvider'
import AuthWrapper from 'components/AuthWrapper'
import UserProvider from 'components/UserProvider'
import translations from 'i18n/translations.json'
import App from 'views/App'

// local
import './index.scss'

const app = (
  <Router>
    <NikeI18nProvider
      currentLanguageTag="en"
      supportedLanguages={[{ name: 'English', tag: 'en' }]}
      translations={translations}
    >
      <NikeDesignSystemProvider>
        <AuthWrapper>
          <ApolloProvider>
            <UserProvider>
              <App />
            </UserProvider>
          </ApolloProvider>
        </AuthWrapper>
      </NikeDesignSystemProvider>
    </NikeI18nProvider>
  </Router>
)

ReactDOM.render(app, document.getElementById('root'))
