// modules
import React, { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import {
  applyTo,
  assoc,
  both,
  identity,
  isEmpty,
  lensProp,
  map,
  over,
  path,
  pathOr,
  pipe,
  prop,
  propOr,
  sortBy,
  when,
} from 'ramda'
import { TranslatableString as TS } from '@nike/i18n-react'
import {
  ButtonGroup,
  Button,
  Checkbox,
  CheckboxGroup,
  Link,
  SidePanel,
  Tab,
  TabGroup,
} from '@nike/eds'

// aliased
import { formatEnum, isChecked, toggleValue } from 'lib/filters'
import { ADMIN_SPORTS_FILTER_QUERY } from 'lib/graphql/query'
import { propTypes, defaultProps } from 'lib/react'

// local
import styles from '../FilterList.module.scss'

const isClearDisabled = both(
  pipe(propOr([], 'sport'), isEmpty),
  pipe(propOr([], 'paralympicSport'), isEmpty),
)

const SportFilter = ({ isOpen, label, onChange, onDismiss, value }) => {
  const [field, setField] = useState('sport')
  const handleTabChange = useCallback(pipe(path(['target', 'id']), setField), [
    setField,
  ])

  const handleChange = useCallback(
    (evt) => {
      const val = path(['target', 'id'], evt)
      onChange(toggleValue(field, val, value))
    },
    [field, onChange, value],
  )

  const { data, loading } = useQuery(ADMIN_SPORTS_FILTER_QUERY, {
    errorPolicy: 'all',
  })
  const options = pipe(
    pathOr({}, ['searchAthletes', 'filterAggregations']),
    over(lensProp('sport'), when(identity, sortBy(prop('key')))),
    over(lensProp('paralympicSport'), when(identity, sortBy(prop('key')))),
  )(data)

  const handleSelectAll = useCallback(() => {
    const allOptions = pipe(propOr([], field), map(prop('key')))(options)
    onChange(assoc(field, allOptions, value))
  }, [field, onChange, options, value])

  const handleClear = useCallback(() => {
    onChange(pipe(assoc('sport', []), assoc('paralympicSport', []))(value))
  }, [onChange, value])

  return (
    <SidePanel
      hasScrim={true}
      headerSlot={label}
      footerSlot={
        <ButtonGroup className={styles.actions}>
          <Button
            variant="secondary"
            onClick={handleClear}
            disabled={isClearDisabled(value)}
          >
            <TS
              stringKey="admin-filter-clear-button"
              primaryValue="Clear"
              description="Label for the button to clear or reset the current filter on the admin athlete search page"
            />
          </Button>
        </ButtonGroup>
      }
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <TabGroup name="sports" activeId={field} onChange={handleTabChange}>
        <Tab id="sport">
          <TS
            stringKey="admin-filter-sport-label"
            primaryValue="Sports"
            description="Label for the sports filter on the admin athlete search page"
          />
        </Tab>
        <Tab id="paralympicSport">
          <TS
            stringKey="admin-filter-paralympic-sport-label"
            primaryValue="Paralympic Sports"
            description="Label for the paralympic sports filter on the admin athlete search page"
          />
        </Tab>
      </TabGroup>
      <div>
        <Link
          className={styles.selectAll}
          variant="secondary"
          as="button"
          onClick={handleSelectAll}
          disabled={loading}
        >
          <TS
            stringKey="admin-filter-select-all-label"
            primaryValue="Select All"
            description="Label for button to select all filter options on the admin athlete search page"
          />
        </Link>
        <CheckboxGroup
          id={`${field}-filter`}
          label={field}
          hideLabel={true}
          name={field}
          onChange={handleChange}
        >
          {pipe(
            propOr([], field),
            map(({ key: term }) => (
              <Checkbox
                label={formatEnum(term)}
                value={term}
                id={term}
                checked={isChecked(field, term, value)}
              />
            )),
          )(options)}
        </CheckboxGroup>
      </div>
    </SidePanel>
  )
}

export default applyTo(
  SportFilter,
  pipe(
    propTypes({
      isOpen: PropTypes.bool,
      label: PropTypes.node,
      onChange: PropTypes.func.isRequired,
      onDismiss: PropTypes.func,
      value: PropTypes.shape({}),
    }),
    defaultProps({
      isOpen: false,
    }),
    memo,
  ),
)
