import React, { useContext, useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { SYNC_ELITE_USER } from 'lib/graphql/query'
import * as elite from '../../lib/elite'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  concat,
} from '@apollo/client'

import config from '../../conf'

const httpLink = new HttpLink({ uri: `${config.API_HOST}/graphql` })

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: localStorage.getItem('token') || null,
    },
  })

  return forward(operation)
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
})

const AuthContext = React.createContext(null)

// eslint-disable-next-line max-statements
export const useAuthProvider = () => {
  const [userPayload, setUserPayload] = useState({
    user: null,
    checked: false,
  })

  const setAuthUser = (user) => {
    setUserPayload({ user, checked: true })
  }

  const setLocalCache = (login) => {
    window.localStorage.setItem('login', JSON.stringify(login))
  }

  const getLocalCache = () => {
    try {
      return JSON.parse(window.localStorage.getItem('login'))
    } catch (error) {}
  }

  const clearLocalCache = () => {
    window.localStorage.removeItem('login')
  }

  const signOut = () => {
    clearLocalCache()
    setUserPayload({ user: null, checked: false })
    window.location.href = '/'
  }

  const signIn = () => {
    signOut()
  }

  const parseLoginAndSetAuthUser = ({ user, auth }) => {
    const authUser = {
      name: `${user.firstName} ${user.lastName}`,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      upmId: user.upmId,
      token_type: 'elite',
      access_token: auth.access_token,
    }

    setAuthUser(authUser)
  }

  const handleLogin = async ({ username, password }) => {
    const auth = await elite.login({ username, password })

    localStorage.setItem('token', auth.access_token)

    const {
      data: { syncEliteUser },
    } = await client.mutate({
      mutation: SYNC_ELITE_USER,
      variables: {
        uuid: auth.user_id,
        accessToken: auth.access_token,
        username,
      },
    })

    const login = {
      auth,
      user: {
        ...syncEliteUser.user,
        email: username,
      },
    }

    console.dir(login, { depth: null })
    setLocalCache(login)
    parseLoginAndSetAuthUser(login)

    return login
  }

  const handleRefresh = async () => {
    const local = getLocalCache()
    if (!local) {
      signOut()
      return
    }

    // we have a user's information in local storage
    const {
      auth: { refresh_token },
      user,
    } = local

    const auth = await elite.refresh({ refresh_token })

    const login = { auth, user }

    console.dir(login, { depth: null })
    setLocalCache(login)
    parseLoginAndSetAuthUser(login)
  }

  const handleRefreshIfNecessary = async (first = false) => {
    const local = getLocalCache()
    if (local) {
      // we have user information in local storage
      const {
        auth: { access_token, refresh_token },
      } = local

      const access = jwt_decode(access_token)
      // this token is going to expire in the next 5 minutes
      if (access.exp * 1000 < new Date().getTime() + 5 * 60_000) {
        const refresh = jwt_decode(refresh_token)
        if (refresh.exp * 1000 > new Date().getTime() + 15_000) {
          // this refresh token is still valid
          return await handleRefresh()
        } else {
          return signOut()
        }
      }

      if (first) {
        // this is the first load and we didn't break out due to expired or refreshing tokens (so we need to set the user)
        parseLoginAndSetAuthUser(local)
      }
    }
  }

  useEffect(() => {
    setInterval(handleRefreshIfNecessary, 60 * 1000)
    handleRefreshIfNecessary(true)
    // eslint-disable-next-line
  }, [])

  const signedIn = Boolean(userPayload.user)

  const hasChecked = userPayload.checked
  const user = userPayload.user

  const valid = hasChecked && user?.upmId && signedIn
  const token = valid
    ? { type: user.token_type, token: user.access_token }
    : null

  const payload = {
    handleLogin,
    token,
    hasChecked,
    user,
    signIn,
    signOut,
    isSignedIn: () => signedIn, // TODO: just pass the value
    needsSignIn: hasChecked && !signedIn,
  }

  return payload
}

// eslint-disable-next-line
const EliteAuthProvider = ({ children, browser }) => {
  return (
    <AuthContext.Provider value={useAuthProvider()}>
      {children}
    </AuthContext.Provider>
  )
}

export const useEliteAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useEliteAuth must be used within a EliteAuthProvider')
  }

  return context
}

export default EliteAuthProvider
