// modules
import React from 'react'
import { NumberFormat } from '@nike/i18n-react'
import cx from 'classnames'
import { path, prop } from 'ramda'

// local
import styles from './PollChoice.module.scss'

const PollChoice = ({ answer, choice }) => {
  return (
    <div
      className={cx(styles.choice, {
        [styles.selected]:
          prop('value', choice) === path(['choice', 'value'], answer),
      })}
      key={prop('value', choice)}
    >
      <div
        className={styles.percentBar}
        style={{
          width: `${prop('percentageOfAnswers', choice) * 100}%`,
        }}
      />
      <span className={styles.choiceLabel}>{prop('name', choice)}</span>
      <span className={styles.choicePercent}>
        <NumberFormat
          number={prop('percentageOfAnswers', choice)}
          //eslint-disable-next-line
          style="percent"
          maximumFractionDigits={0}
        />
      </span>
    </div>
  )
}

export default PollChoice
