// modules
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { applyTo, pipe } from 'ramda'
import { TranslatableString as TS } from '@nike/i18n-react'
import cx from 'classnames'

// aliased
import ErrorBoundary from 'components/ErrorBoundary'
import { propTypes, defaultProps } from 'lib/react'
import { ReactComponent as PollsIcon } from 'icons/checklist.svg'
import { ReactComponent as AnalyticsIcon } from 'icons/insights.svg'
import { ReactComponent as AthletesIcon } from 'icons/medal.svg'

// local
import Header from './components/Header'
import SideNav from './components/SideNav'
import styles from './AdminLayout.module.scss'

export default applyTo(
  ({ children, className }) => {
    return (
      <div className={cx(styles.container, className)}>
        <SideNav
          className={styles.nav}
          routes={
            [
              // {
              //   path: 'geo_dashboard',
              //   icon: <AnalyticsIcon />,
              //   label: (
              //     <TS
              //       description="Rep dashboards for pre buy focused on geo level"
              //       primaryValue="Geo Dashboard"
              //       stringKey="admin-nav-athletes-geo"
              //     />
              //   ),
              // },
              // {
              //   path: 'analytics',
              //   icon: <AnalyticsIcon />,
              //   label: (
              //     <TS
              //       description="Admin menu label for Analytics page"
              //       primaryValue="Analytics"
              //       stringKey="admin-nav-analytics"
              //     />
              //   ),
              // },
              // {
              //   path: 'polls',
              //   icon: <PollsIcon />,
              //   label: (
              //     <TS
              //       description="Admin menu label for Polls page"
              //       primaryValue="Polls"
              //       stringKey="admin-nav-polls"
              //     />
              //   ),
              // },
            ]
          }
        />
        <div className={styles.content}>
          <Header className={styles.header} />
          <main className={styles.main} id="main">
            <ErrorBoundary className={styles.errorBoundary}>
              {children}
            </ErrorBoundary>
          </main>
        </div>
      </div>
    )
  },
  pipe(
    propTypes({
      children: PropTypes.node,
      className: PropTypes.string,
    }),
    defaultProps({
      children: null,
      className: null,
    }),
    memo,
  ),
)
